import { csrfToken } from "./helpers";

export const sendSignOut = () => {
  return fetch("/sign-out", {
    method: "DELETE",
    headers: {
      "X-CSRF-Token": csrfToken,
    },
    credentials: "same-origin",
  })
    .then((response) => {
      if (response.ok) {
        window.location.href = "/";
      } else {
        console.error("Sign out failed");
      }
    })
    .catch((error) => {
      console.error("Sign out failed", error);
    });
};

export function countOccurrences<T>(array: T[]): Array<[T, number]> {
  const counts: Record<T, number> = {} as Record<T, number>;

  array.forEach((item) => {
    if (counts[item]) {
      counts[item]++;
    } else {
      counts[item] = 1;
    }
  });

  return Object.entries(counts);
}
