import React from "react";
import {
  Table as ShadcnTable,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "~/shadcn/components/ui/table";
import { EmptyRow } from "~/components/ui/EmptyRow";
import { flexRender, Table } from "@tanstack/react-table";
import { TablePaginationControls } from "~/components/ui/TablePaginationControls";

export function PageEventTableBase({
  table,
  didClickRow,
}: {
  table: Table<any>;
  didClickRow: (row: any) => void;
}) {
  return (
    <div className="flex flex-col gap-2 mt-4">
      <ShadcnTable>
        <TableHeader className="text-xs">
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <TableHead key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(header.column.columnDef.header, header.getContext())}
                  </TableHead>
                );
              })}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row) => (
              <TableRow
                key={row.id}
                data-state={row.getIsSelected() && "selected"}
                className="cursor-pointer"
                onClick={() => {
                  didClickRow(row.original);
                }}
              >
                {row.getVisibleCells().map((cell) => (
                  <TableCell
                    key={cell.id}
                    style={{
                      width: cell.column.getSize(),
                    }}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <EmptyRow
              title="No changes detected"
              description="We are keeping track of your competitors and will notify you when we detect changes"
              columns={table.getAllColumns().length}
            />
          )}
        </TableBody>
      </ShadcnTable>
      <TablePaginationControls table={table} />
    </div>
  );
}
