import React, { useEffect, useState } from "react";

export const LoadingView = ({ name }: { name: string }) => {
  const [dots, setDots] = useState("");

  useEffect(() => {
    const intervalId = setInterval(() => {
      setDots((prevDots) => {
        if (prevDots.length === 3) {
          return "";
        }
        return prevDots + ".";
      });
    }, 500);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <div className="flex justify-center flex-col h-full items-center">
      <p className="text-sm text-slate-700 text-center">
        Fetching latest {name}
        {dots}
      </p>
    </div>
  );
};
