import React from "react";
import { TableCell, TableRow } from "~/shadcn/components/ui/table";
import { AddCompanyButton } from "../company/AddCompanyButton";
import { PrimaryButton } from "./PrimaryButton";
import { useNavigate } from "react-router-dom";
import { useSidebar } from "~/providers/SidebarProvider";
import { PlusIcon } from "@radix-ui/react-icons";

export const EmptyRow = ({
  emoji = "",
  title,
  description,
  columns,
  numCompanies,
}: {
  emoji?: string;
  title: string;
  description: string;
  columns: number;
  showAddCompany?: boolean;
  numCompanies?: number;
}) => {
  const navigate = useNavigate();
  const { setSelectedTab } = useSidebar();

  return (
    <TableRow>
      <TableCell colSpan={columns} className="h-24 text-center text-sm text-slate-6000">
        {numCompanies < 3 ? (
          <div className="flex flex-col items-center gap-2 py-8">
            <div className="text-4xl pb-2">💡️</div>
            <div className="font-medium">Track more companies</div>
            <div className="text-slate-700 max-w-lg mb-4">
              Seems like you are only tracking {numCompanies}{" "}
              {numCompanies === 1 ? "company" : "companies"}. We recommend tracking around ~10 to
              get the most out of Campana.
            </div>
            <PrimaryButton
              onClick={() => {
                setSelectedTab("following");
                navigate("/following", {
                  state: { id: Math.random().toString(), showNewCompanyForm: true },
                });
              }}
            >
              <PlusIcon className="w-4 h-4" />
              Add company
            </PrimaryButton>
          </div>
        ) : (
          <div className="flex flex-col items-center gap-2 py-8">
            <div className="text-4xl pb-2">🏖️</div>
            <div className="font-medium">{title}</div>
            <div className="text-slate-700 max-w-sm mb-4">{description}</div>
          </div>
        )}
      </TableCell>
    </TableRow>
  );
};
