import React from "react";
import { HeaderContainer } from "../ui/HeaderContainer";
import { Logo } from "../ui/Logo";
import { cn } from "~/utils/helpers";
import { Tab } from "./Tab";
import { useSidebar } from "../../providers/SidebarProvider";
import { useApp } from "~/providers/AppProvider";
import ProfileButton from "../ui/ProfileButton";
import { TAB_ITEMS } from "./TabItems";

const Profile = () => {
  const { user } = useApp();
  return user ? (
    <>
      <ProfileButton user={user} />
    </>
  ) : (
    <a href="/sign-up">
      <Logo />
    </a>
  );
};

const Tabs = () => {
  const { selectedTab, selectAndNavigateToTab } = useSidebar();

  return (
    <div className="flex flex-col gap-3 mt-2">
      {TAB_ITEMS.map((tab) => (
        <Tab
          key={tab.selected}
          selected={selectedTab === tab.selected}
          logo={tab.logo}
          selectedLogo={tab.selectedLogo}
          area={tab.area}
          onClick={() => selectAndNavigateToTab(tab.selected)}
        />
      ))}
    </div>
  );
};

export const ContentHeader = () => {
  return (
    <HeaderContainer styles={["hidden sm:flex border-r-[1px]"]}>
      <div
        className={cn(
          "flex items-center h-full justify-center overflow-y-auto no-scrollbar w-[54px]",
        )}
      >
        <Tabs />
      </div>
      <div className="flex w-full justify-center">
        <Profile />
      </div>
    </HeaderContainer>
  );
};
