import React, { useEffect, useState } from "react";
import MainContentCard from "../ui/MainContentCard";
import { SectionHeader } from "../ui/SectionHeader";
import { NewsRow } from "./NewsRow";
import {
  ColumnDef,
  ColumnFiltersState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";

import { Table, TableBody, TableCell, TableRow } from "~/shadcn/components/ui/table";
import { TablePaginationControls } from "../ui/TablePaginationControls";
import { FilterPillButton } from "../FilterPill";
import { EmptyRow } from "../ui/EmptyRow";
import { useQuery } from "@tanstack/react-query";
import { cn, fetch_get } from "~/utils/helpers";
import { CompanyArticle } from "./NewsRow";
import { LoadingView } from "../ui/LoadingView";

export const columns: ColumnDef<CompanyArticle>[] = [
  {
    accessorKey: "companyName",
    header: "article",
    cell: ({ row }) => (
      <NewsRow
        id={row.original.id}
        url={row.original.url}
        title={row.original.title}
        date={row.original.date}
        source={row.original.source}
        snippet={row.original.snippet}
        imageUrl={row.original.imageUrl}
        eventType={row.original.eventType}
        companyName={row.original.companyName}
      />
    ),
  },
];

interface NewsTableProps {
  data: CompanyArticle[];
  pageSize: number;
}

export const NewsTable = ({ data, pageSize }: NewsTableProps) => {
  const companies: string[] = Array.from(new Set(data.map((news) => news.companyName)));
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: pageSize,
  });

  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const table = useReactTable({
    data,
    columns,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      pagination,
      columnFilters,
    },
    defaultColumn: {
      minSize: 0,
      size: Number.MAX_SAFE_INTEGER,
      maxSize: Number.MAX_SAFE_INTEGER,
    },
  });

  const [companyFilter, setCompanyFilter] = useState<string | null>(null);

  useEffect(() => {
    if (companyFilter) {
      setColumnFilters([{ id: "companyName", value: companyFilter }]);
    } else {
      setColumnFilters([]);
    }
  }, [companyFilter]);

  return (
    <>
      <div className="flex gap-4 w-full mt-4">
        <FilterPillButton
          title="Company"
          source={companies}
          filter={companyFilter}
          setFilter={setCompanyFilter}
        />
      </div>
      <div className="flex flex-col gap-2 w-full">
        <Table className="w-full">
          <TableBody className="w-full">
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  className="cursor-pointer"
                  data-state={row.getIsSelected() && "selected"}
                  onClick={() => {
                    window.open(row.original.url, "_blank");
                  }}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id} className="w-full">
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <EmptyRow
                title="No news detected"
                description="We are keeping track of news about your competitors and will notify you when we detect changes"
                columns={columns.length}
              />
            )}
          </TableBody>
        </Table>
        <TablePaginationControls table={table} />
      </div>
    </>
  );
};

export const NewsView = () => {
  const { isLoading, data } = useQuery({
    queryKey: ["company_articles"],
    queryFn: () => fetch_get("/company_articles").then((response) => response.json()),
  });

  return (
    <MainContentCard>
      <div
        className={cn(
          "flex flex-col gap-2 items-center justify-center w-full px-16 pb-12",
          isLoading && "h-full",
        )}
      >
        <SectionHeader title="News" />
        {isLoading ? <LoadingView name="news" /> : <NewsTable data={data} pageSize={10} />}
      </div>
    </MainContentCard>
  );
};
