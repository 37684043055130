import React from "react";
import { cn } from "~/utils/helpers";

export const ProgressIndicator = ({ step, totalSteps }) => {
  return (
    <div className="flex gap-2 h-[4px]">
      {Array.from({ length: totalSteps }, (_, index) => (
        <div
          key={index}
          className={cn("flex flex-1 bg-slate-100 rounded-full", step > index && "bg-[#3B7EFF]")}
        />
      ))}
    </div>
  );
};
