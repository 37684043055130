import React, { useState } from "react";
import { ChevronRight } from "lucide-react";
import { cn } from "~/utils/helpers";
import { HighlightModalView } from "./HighlightModalView";
import { Insight } from "~/models/Insight";
import { Company } from "~/models/Company";
import { CompanyLogo } from "~/components/ui/CompanyLogo";
import CategoryTypePill from "~/components/ui/CategoryTypePill";

export const HighlightCell = ({
  insight,
  index,
  company,
}: {
  insight: Insight;
  index: number;
  company?: Company;
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  return (
    <>
      <div
        key={index}
        onClick={() => {
          setIsDialogOpen(!isDialogOpen);
        }}
        className={cn(
          "flex flex-col gap-2 border p-4 rounded-lg shadow-sm cursor-pointer",
          index === 0 ? "ml-16" : "",
        )}
      >
        <div className="flex items-center w-full gap-2">
          <CompanyLogo url={company?.url} />
          <div className="text-sm font-medium w-full">{insight.company_name}</div>
          <ChevronRight className="w-4 h-4 text-blue-600" />
        </div>
        <div className="w-[320px] text-left h-full mb-4">{insight.title}</div>

        {insight.category && <CategoryTypePill category={insight.category} />}
      </div>
      <HighlightModalView
        company={company}
        insight={insight}
        isDialogOpen={isDialogOpen}
        setIsDialogOpen={setIsDialogOpen}
      />
    </>
  );
};
