import { useQuery } from "@tanstack/react-query";
import { fetch_get } from "~/utils/helpers";

export default function useTrackedPagesForCompany(companyId: string) {
  return useQuery({
    queryKey: ["tracked_pages", companyId],
    queryFn: () =>
      fetch_get(`/tracked_pages?company_id=${companyId}`).then((response) => response.json()),
  });
}
