import React, { useState } from "react";
import MainContentCard from "../ui/MainContentCard";
import { SectionHeader } from "../ui/SectionHeader";
import { Loader2 } from "lucide-react";
import { GearIcon, InfoCircledIcon } from "@radix-ui/react-icons";
import { Dialog } from "~/shadcn/components/ui/dialog";
import { URLManagementDialog } from "../ui/URLManagementDialog";
import { TrackedPageDiff } from "~/models/TrackedPage";
import { useQuery } from "@tanstack/react-query";
import { fetch_get } from "~/utils/helpers";
import { PrimaryButton } from "../ui/PrimaryButton";
import { HoverCard, HoverCardContent, HoverCardTrigger } from "~/shadcn/components/ui/hover-card";
import { useApp } from "~/providers/AppProvider";
import { useSidebar } from "~/providers/SidebarProvider";
import { AddCompanyButton } from "../company/AddCompanyButton";
import { WebsiteMonitoringTable } from "./WebsiteMonitoringTable";
import { ColumnFiltersState } from "@tanstack/react-table";
import { WebsiteMonitoringFilters } from "./WebsiteMonitoringFilters";

export const ManageTrackedPagesButton = () => {
  const [showManagementForm, setShowManagementForm] = useState(false);
  const { isLoading, data } = useQuery({
    queryKey: ["tracked_pages"],
    queryFn: () => fetch_get("/tracked_pages").then((response) => response.json()),
  });

  return (
    <Dialog open={showManagementForm} onOpenChange={setShowManagementForm}>
      <PrimaryButton
        type="secondary"
        onClick={() => {
          setShowManagementForm(true);
        }}
      >
        <GearIcon className="w-4 h-4" />
        <span className="whitespace-nowrap">Manage tracked pages</span>
        {isLoading ? (
          <Loader2 className="w-4 h-4 ml-2 transition-all animate-spin" />
        ) : (
          data && <span className="text-xs font-semibold text-blue-700 ml-2">{data.length}</span>
        )}
      </PrimaryButton>
      <URLManagementDialog />
    </Dialog>
  );
};

const WebsiteMonitoringBaseView = ({ data }: { data: TrackedPageDiff[] }) => {
  const { user } = useApp();
  const { selectAndNavigateToTab } = useSidebar();
  const [showCompanyForm, setShowCompanyForm] = useState(false);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

  return (
    <MainContentCard enableHorizontalPadding={true}>
      <div className="flex items-center gap-2 mb-2 w-fit">
        <SectionHeader title="Website Monitoring" />
        <HoverCard>
          <HoverCardTrigger>
            <InfoCircledIcon className="w-4 h-4 text-slate-500" />
          </HoverCardTrigger>
          <HoverCardContent>
            <div className="text-sm">
              <strong>What does website monitoring do?</strong>
              <br />
              Website monitoring notifies you about new and deleted pages of a company's website via
              their sitemap.
            </div>
          </HoverCardContent>
        </HoverCard>
      </div>

      <div className="flex items-center justify-between gap-2">
        <WebsiteMonitoringFilters diffs={data} setColumnFilters={setColumnFilters} />

        <div className="w-full" />
        <AddCompanyButton isOpen={showCompanyForm} setIsOpen={setShowCompanyForm} />
        <PrimaryButton
          type="secondary"
          onClick={() => {
            selectAndNavigateToTab("following");
          }}
        >
          <GearIcon className="w-4 h-4" />
          <span className="whitespace-nowrap">Manage companies</span>
          <span className="text-xs font-semibold text-blue-700 ml-2">{user.companies.length}</span>
        </PrimaryButton>
      </div>

      <WebsiteMonitoringTable
        data={data}
        columnFilters={columnFilters}
        setColumnFilters={setColumnFilters}
      />
    </MainContentCard>
  );
};

export const WebsiteMonitoring = () => {
  const { isLoading, data } = useQuery({
    queryKey: ["page_monitoring"],
    queryFn: () => fetch_get("/page_monitoring").then((response) => response.json()),
  });

  if (isLoading) {
    return null;
  }

  return <WebsiteMonitoringBaseView data={data?.diffs || []} />;
};
