import React, { useState } from "react";
import {
  ColumnFiltersState,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { PageEventTableBase } from "../ui/PageEventTableBase";
import { useNavigate } from "react-router-dom";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "~/shadcn/components/ui/dropdown-menu";
import { TrackedPageDiff } from "~/models/TrackedPage";
import { ColumnDef } from "@tanstack/react-table";
import { PageEventTypePill } from "../ui/PageEventTypePill";
import { CaretSortIcon } from "@radix-ui/react-icons";
import { Button } from "~/shadcn/components/ui/button";
import { MoreHorizontal } from "lucide-react";
import CategoryTypePill from "../ui/CategoryTypePill";
import { URLSummaryCell } from "../ui/URLSummaryCell";

export const TrackedPageDiffTableColumns: ColumnDef<TrackedPageDiff>[] = [
  {
    accessorKey: "kind",
    header: "Type",
    cell: ({ row }) => {
      return <PageEventTypePill type={row.original.kind ? "changed" : "unchanged"} />;
    },
    size: 80,
  },
  {
    accessorKey: "category",
    header: "Category",
    cell: ({ row }) => {
      const category = row.original.data?.category;
      if (!category || category === "Unknown") {
        return <div />;
      }
      return <CategoryTypePill category={category} />;
    },
    size: 40,
    filterFn: (row, _, filterValue) => {
      return row.original.data?.category === filterValue;
    },
  },
  {
    accessorKey: "url",
    header: "Change",
    cell: ({ row }) => {
      const url = row.getValue("url") as string;
      const summary = row.original.data.summary;
      return <URLSummaryCell url={url} description={summary} />;
    },
    size: 300,
  },
  {
    accessorKey: "company_name",
    header: () => <div className="text-xs text-right">Company</div>,
    cell: ({ row }) => <div className="text-xs text-right">{row.getValue("company_name")}</div>,
    size: 120,
  },
  {
    accessorKey: "date",
    header: ({ column }) => {
      return (
        <div className="flex items-center flex-col">
          <Button
            variant="ghost"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            <div className="text-xs">Date</div>
            <CaretSortIcon className="ml-2 h-4 w-4" />
          </Button>
        </div>
      );
    },
    cell: ({ row }) => {
      const date = row.original.date;
      if (!date) {
        return <div className="text-center text-xs text-muted-foreground">-</div>;
      }
      const relativeDate = new Date(date).toLocaleDateString(navigator.language, {
        year: "numeric",
        month: "short",
        day: "numeric",
      });
      return <div className="text-center text-xs text-muted-foreground">{relativeDate}</div>;
    },
    size: 140,
    sortingFn: (rowA, rowB) => {
      const dateA = rowA.original.date;
      const dateB = rowB.original.date;
      return new Date(dateA).getTime() - new Date(dateB).getTime();
    },
  },
  {
    id: "actions",
    cell: ({ row }) => {
      return (
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="ghost" className="h-8 w-8 p-0">
              <span className="sr-only">Open menu</span>
              <MoreHorizontal className="h-4 w-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuItem>Share</DropdownMenuItem>
            <DropdownMenuSeparator />
            <DropdownMenuItem>Delete</DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      );
    },
    size: 40,
  },
];

export const useTrackedPageDiffTable = (
  data: TrackedPageDiff[],
  columnFilters: ColumnFiltersState,
  setColumnFilters: (columnFilters: ColumnFiltersState) => void,
) => {
  const [sorting, setSorting] = useState<SortingState>([{ id: "date", desc: true }]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  return useReactTable({
    data,
    columns: TrackedPageDiffTableColumns,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getSortedRowModel: getSortedRowModel(),
    defaultColumn: {
      size: 200,
      minSize: 50,
      maxSize: 2000,
    },
    state: {
      pagination,
      columnFilters,
      sorting,
    },
  });
};

export const TrackedPageDiffTable = ({
  data,
  columnFilters,
  setColumnFilters,
}: {
  data: TrackedPageDiff[];
  columnFilters: ColumnFiltersState;
  setColumnFilters: (columnFilters: ColumnFiltersState) => void;
}) => {
  const table = useTrackedPageDiffTable(data, columnFilters, setColumnFilters);
  const navigate = useNavigate();

  return (
    <PageEventTableBase
      table={table}
      didClickRow={(row: TrackedPageDiff) => {
        navigate({
          pathname: `/page-changes/${row.tracked_page_id}`,
          search: `?pageChangeId=${row.id}`,
        });
      }}
    />
  );
};
