import React from "react";

export const PageDiffStats = ({
  added,
  removed,
  unchanged,
}: {
  added: number;
  removed: number;
  unchanged: number;
}) => {
  const total = added + removed + unchanged;
  const totalSquares = 5;

  let greenCount = 0;
  let redCount = 0;
  let grayCount = totalSquares;

  if (total > 0) {
    greenCount = Math.round((added / total) * totalSquares);
    redCount = Math.round((removed / total) * totalSquares);
    grayCount = totalSquares - greenCount - redCount;

    // Adjust counts to ensure the total is exactly 5
    if (greenCount + redCount + grayCount !== totalSquares) {
      grayCount = totalSquares - greenCount - redCount;
    }
  }
  return (
    <div className="flex items-center gap-2">
      <div className="text-xs text-green-500">+{added}</div>
      <div className="text-xs text-red-500">-{removed}</div>

      <div className="flex gap-1">
        <Dots count={greenCount} color="green-500" />
        <Dots count={redCount} color="red-500" />
        <Dots count={grayCount} color="gray-300" />
      </div>
    </div>
  );
};

const Dots = ({ count, color }) =>
  Array(Math.max(count, 0))
    .fill(null)
    .map((_, i) => <div key={i} className={`w-2 h-2 bg-${color}`} />);
