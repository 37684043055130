import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { PlusIcon } from "lucide-react";
import { AddURLDialog } from "./AddURLDialog";
import { Dialog } from "~/shadcn/components/ui/dialog";
import { PrimaryButton } from "../ui/PrimaryButton";

export const AddTrackedPagesButton = ({ companyId }: { companyId?: number }) => {
  const [showURLForm, setShowURLForm] = useState(false);
  const { state } = useLocation();
  const { id, showNewTrackedPageForm } = state || { id: null, showNewTrackedPageForm: false };

  useEffect(() => {
    setShowURLForm(showNewTrackedPageForm);
  }, [id, showNewTrackedPageForm]);

  return (
    <Dialog open={showURLForm} onOpenChange={setShowURLForm}>
      <PrimaryButton
        onClick={() => {
          setShowURLForm(true);
        }}
      >
        <PlusIcon className="w-4 h-4" />
        <span className="whitespace-nowrap">Track new page</span>
      </PrimaryButton>
      <AddURLDialog targetCompanyId={companyId} closeDialog={() => setShowURLForm(false)} />
    </Dialog>
  );
};
