import React from "react";
import { createBrowserRouter, Navigate, RouterProvider } from "react-router-dom";
import { QueryClientProvider } from "@tanstack/react-query";
import { queryClient } from "../utils/client";
import { Onboarding } from "./onboarding/Onboarding";
import { ComponentsPlayground } from "./ui/ComponentsPlayground";
import { AppProvider, User } from "../providers/AppProvider";
import { ErrorBoundary } from "react-error-boundary";
import { RootView } from "./RootView";
import { NewsView } from "./news/NewsView";
import { ReportsView } from "./reports/ReportsView";
import Settings from "./settings/Settings";
import CompanyList from "./company/CompanyView";
import { ReportsDetailView } from "./reports/ReportsDetailView";
import { TrackedPagesView } from "./trackedPages/TrackedPagesView";
import { WebsiteMonitoring } from "./pageDiff/WebsiteMonitoring";
import CompanyDetailView from "./company/CompanyDetailView";
import { PageDetailView } from "./pageDiffDetail/PageDetailView";

const routes = [
  {
    path: "/",
    element: <RootView />,
    children: [
      {
        path: "",
        element: <ReportsView />,
      },
      {
        path: "reports",
        element: <ReportsView />,
      },
      {
        path: "reports/:reportId",
        element: <ReportsDetailView />,
      },
      {
        path: "following/:companyId",
        element: <CompanyDetailView />,
      },
      {
        path: "page-changes",
        element: <TrackedPagesView />,
      },
      {
        path: "page-changes/:trackedPageId",
        element: <PageDetailView />,
      },
      {
        path: "website-monitoring",
        element: <WebsiteMonitoring />,
      },
      {
        path: "news",
        element: <NewsView />,
      },
      {
        path: "settings",
        element: <Settings />,
      },
      {
        path: "following",
        element: <CompanyList />,
      },
    ],
  },
  {
    path: "/onboarding",
    element: <Onboarding />,
  },
];

if (process.env.NODE_ENV === "development") {
  routes.push({
    path: "/components",
    element: <ComponentsPlayground />,
  });
}

routes.push({
  path: "*",
  element: <Navigate to="/" replace />,
});

const router = createBrowserRouter(routes);

const ErrorNotice = () => {
  return <div>Error</div>;
};

export const App = ({ initialUser }: { initialUser: User }) => {
  return (
    <ErrorBoundary FallbackComponent={ErrorNotice}>
      <QueryClientProvider client={queryClient}>
        <AppProvider initialUser={initialUser}>
          <RouterProvider router={router}></RouterProvider>
        </AppProvider>
      </QueryClientProvider>
    </ErrorBoundary>
  );
};
