import React, { useEffect, useRef, useState } from "react";
import { DotFilledIcon } from "@radix-ui/react-icons";
import { useInfiniteQuery } from "@tanstack/react-query";
import { useVirtualizer } from "@tanstack/react-virtual";
import { cn, fetch_get } from "~/utils/helpers";
import { Switch } from "~/shadcn/components/ui/switch";
import { Separator } from "~/shadcn/components/ui/separator";
import CategoryTypePill from "~/components/ui/CategoryTypePill";

const HistoryCell = ({ h, isLastEntry, selectedDiff, setSelectedDiff }) => {
  return (
    <div className="flex w-[90%] h-[154px]">
      <div className="flex flex-col items-center ">
        <DotFilledIcon
          className={cn("h-6 w-6", selectedDiff?.id === h.id ? "text-blue-500" : "text-slate-300")}
        />

        {!isLastEntry && <div className="w-[2px] h-[124px] bg-slate-200" />}
      </div>

      <button
        key={h.id}
        className={cn(
          "w-full h-[130px] rounded-md p-4 shadow-sm transition-all flex flex-col gap-2 text-sm",
          !h.significant && selectedDiff?.id !== h.id && "opacity-40",
          h.selectedDiff?.id === h.id
            ? "border-blue-500 border-[1.3px] shadow-blue-400/50"
            : "border border-slate-200",
        )}
        onClick={() => setSelectedDiff(h)}
      >
        {h.data.category && <CategoryTypePill category={h.data.category} />}
        <p
          className={cn(
            "text-start line-clamp-2 h-full",
            selectedDiff?.id === h.id && "text-blue-600 font-medium",
          )}
        >
          {h.data.summary ?? "No changes"}
        </p>
        <div className="text-slate-600 text-xs">{new Date(h.date).toLocaleDateString("en-US")}</div>
      </button>
    </div>
  );
};

async function fetchServerPage(
  trackedPageId: string,
  pageChangeId: string,
  page: number = 0,
  hideUnchanged: boolean,
): Promise<{ rows: string[]; nextOffset: number }> {
  const pageChangeIdParam = pageChangeId ? `pageChangeId=${pageChangeId}&` : "";
  const response = await fetch_get(
    `/tracked_pages/${trackedPageId}/paginated_page_diffs/?${pageChangeIdParam}page=${page}&hideUnchanged=${
      hideUnchanged ? "1" : "0"
    }`,
  );
  const data = await response.json();
  const history = data.history ?? [];
  return { rows: history, nextOffset: data.has_next_page ? page + 1 : undefined };
}

export default function PageDiffHistoryList({
  trackedPageId,
  pageChangeId,
  selectedDiff,
  setSelectedDiff,
  latestDiff,
  setLatestDiff,
}) {
  const [hideUnchanged, setHideUnchanged] = useState(true);
  const {
    status,
    data,
    error,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    refetch,
  } = useInfiniteQuery({
    queryKey: ["tracked_page_history", pageChangeId],
    queryFn: ({ pageParam = 0 }) =>
      fetchServerPage(trackedPageId, pageChangeId, pageParam, hideUnchanged),
    getNextPageParam: (lastGroup, groups) => {
      return lastGroup.nextOffset !== undefined ? lastGroup.nextOffset : undefined;
    },
    initialPageParam: 0,
  });

  const allRows = data ? data.pages.flatMap((d) => d.rows) : [];
  const parentRef = useRef();

  const rowVirtualizer = useVirtualizer({
    count: hasNextPage ? allRows.length + 1 : allRows.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 154,
    overscan: 5,
  });

  useEffect(() => {
    const [lastItem] = [...rowVirtualizer.getVirtualItems()].reverse();
    if (!lastItem) {
      return;
    }

    if (selectedDiff === null) {
      setSelectedDiff(allRows[0]);
    }

    if (latestDiff === null) {
      setLatestDiff(allRows[0]);
    }

    if (lastItem.index >= allRows.length - 1 && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [
    hasNextPage,
    fetchNextPage,
    allRows.length,
    isFetchingNextPage,
    rowVirtualizer.getVirtualItems(),
  ]);

  useEffect(() => {
    refetch();
  }, [hideUnchanged]);

  return (
    <div className="flex flex-col overflow-y-auto items-center">
      <div className="flex items-center gap-2 p-2 justify-end w-full">
        <div className="text-slate-500 text-xs">Hide unchanged</div>
        <Switch id="show-unchanged" checked={hideUnchanged} onCheckedChange={setHideUnchanged} />
      </div>

      <Separator />

      {status === "error" ? (
        <span>Error: {(error as Error).message}</span>
      ) : (
        <div ref={parentRef} className="w-full overflow-y-auto">
          <div
            className="relative top-4 left-4"
            style={{
              height: `${rowVirtualizer.getTotalSize()}px`,
            }}
          >
            {rowVirtualizer.getVirtualItems().map((virtualRow) => {
              const isLoaderRow = virtualRow.index > allRows.length - 1;
              const post = allRows[virtualRow.index];

              return (
                <div
                  key={virtualRow.index}
                  className="absolute w-full"
                  style={{
                    height: `${virtualRow.size}px`,
                    transform: `translateY(${virtualRow.start}px)`,
                  }}
                >
                  {isLoaderRow ? (
                    hasNextPage && <span className="text-sm">Loading more...</span>
                  ) : (
                    <HistoryCell
                      h={post}
                      isLastEntry={virtualRow.index === allRows.length - 1}
                      selectedDiff={selectedDiff}
                      setSelectedDiff={setSelectedDiff}
                    />
                  )}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}
