import React from 'react';

export enum PulseColor {
    RED = 'red',
    GREEN = 'green',
}

export interface PulsingDotProps {
    color: PulseColor;
}

export const PulsingDot: React.FC<PulsingDotProps> = ({ color }) => {
    const colorClass = color === PulseColor.RED ? 'bg-red-500' : 'bg-green-500';
    const opacityClass = color === PulseColor.RED ? 'bg-red-400' : 'bg-green-400';

    return (
        <span className="relative flex h-3 w-3 items-center justify-center">
            <span className={`animate-ping absolute inline-flex h-full w-full rounded-full opacity-70 ${opacityClass}`}></span>
            <span className={`relative inline-flex rounded-full h-2 w-2 ${colorClass}`}></span>
        </span>
    );
};

