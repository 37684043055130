import React from "react";
import { cn } from "~/utils/helpers";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "~/shadcn/components/ui/tooltip";

interface TabProps {
  logo?: React.ReactNode;
  selectedLogo?: React.ReactNode;
  area: string;
  selected?: boolean;
  style?: string;
  onClick?: () => void;
}

export const Tab = ({ logo, selectedLogo, area, selected, style, onClick }: TabProps) => {
  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <button
            onClick={onClick}
            className={cn("flex items-center justify-center transition-all p-2", style)}
          >
            <div className="h-[24px] w-[24px] flex justify-center items-center">{ selected ? selectedLogo : logo}</div>
          </button>
        </TooltipTrigger>
        <TooltipContent>
          <p>{area}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};
