import React from "react";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "~/shadcn/components/ui/accordion";
import { HoverCard, HoverCardContent, HoverCardTrigger } from "~/shadcn/components/ui/hover-card";
import { QuestionMarkCircledIcon } from "@radix-ui/react-icons";

export const AddTrackedURLQuestionsDescription = () => {
  return (
    <HoverCard>
      <HoverCardTrigger>
        <span className="text-left">Be notified when a page changes</span>
        <QuestionMarkCircledIcon className="inline h-4 w-4 ml-2" />
      </HoverCardTrigger>
      <HoverCardContent>
        <div className="text-left">
          <div>FAQ</div>
          <AddTrackedURLQuestionsAccordion />
        </div>
      </HoverCardContent>
    </HoverCard>
  );
};

const AddTrackedURLQuestionsAccordion = () => {
  return (
    <Accordion type="single" collapsible className="w-full">
      <AccordionItem value="item-1">
        <AccordionTrigger>
          <div className="text-left">Why do I have to manually enter URLs?</div>
        </AccordionTrigger>
        <AccordionContent>
          Campana automatically tracks new and deleted pages. This step is specifically for tracking
          changes in existing pages over time.
        </AccordionContent>
      </AccordionItem>
      <AccordionItem value="item-2">
        <AccordionTrigger>
          <div className="text-left">Why can't you track every single page of a company?</div>
        </AccordionTrigger>
        <AccordionContent>
          If you want to do this, "Select all" in the UI. Note that this could lead to your weekly
          reports being very long.
        </AccordionContent>
      </AccordionItem>
      <AccordionItem value="item-3">
        <AccordionTrigger>
          <div className="text-left">How often are these pages being snapshotted?</div>
        </AccordionTrigger>
        <AccordionContent>
          We snapshot pages every 24 hours. If you want to track more frequently, let us know
          through the feedback button.
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};
