import React, { useEffect, useState } from "react";
import { Button } from "~/shadcn/components/ui/button";
import { Table, TableBody, TableCell, TableRow } from "~/shadcn/components/ui/table";
import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useApp } from "~/providers/AppProvider";
import { Company } from "~/models/Company";
import CompanyRowDropdown from "./CompanyRowDropdown";
import { useLocation, useNavigate } from "react-router-dom";
import ViewContainer from "~/components/ui/ViewContainer";
import { EmptyRow } from "~/components/ui/EmptyRow";
import { SectionHeader } from "~/components/ui/SectionHeader";
import { PrimaryButtonContent } from "../ui/PrimaryButton";
import { DropdownMenu, DropdownMenuTrigger } from "~/shadcn/components/ui/dropdown-menu";
import { AddNewButtonContent } from "../navBar/AddNewButton";
import { ChevronRight, PlusIcon } from "lucide-react";
import { Dialog } from "~/shadcn/components/ui/dialog";
import { NewCompanyDialog } from "./AddCompanyButton";
import { AddURLDialog } from "../trackedPages/AddURLDialog";

export const COLUMNS: ColumnDef<Company>[] = [
  {
    accessorKey: "name",
    header: "Name",
    cell: ({ row }) => <div className="font-medium text-xs py-4">{row.getValue("name")}</div>,
    size: 140,
  },
  {
    accessorKey: "url",
    header: "URL",
    cell: ({ row }) => <div className="text-xs text-slate-500">{row.getValue("url")}</div>,
    filterFn: "equalsString",
    size: 300,
  },
  {
    id: "chevron",
    cell: ({ row }) => {
      return (
        <div className="flex justify-end">
          <ChevronRight className="w-4 h-4 text-slate-500" />
        </div>
      );
    },
    size: 400,
  },
];

function CompanyTableBase({
  data,
  columnFilters,
  pageSize,
  didClick,
}: {
  data: Company[];
  columnFilters: ColumnFiltersState;
  pageSize: number;
  didClick: (row: Company) => void;
}) {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({});
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: pageSize,
  });

  const table = useReactTable({
    data,
    columns: COLUMNS,
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    defaultColumn: {
      size: 200,
      minSize: 50,
      maxSize: 500,
    },
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      pagination,
    },
  });

  return (
    <div className="flex flex-col gap-2 w-full">
      <Table
        {...{
          style: {
            width: "100%",
          },
        }}
      >
        <TableBody>
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row) => (
              <TableRow
                key={row.id}
                data-state={row.getIsSelected() && "selected"}
                className="cursor-pointer"
                onClick={() => {
                  didClick(row.original);
                }}
              >
                {row.getVisibleCells().map((cell) => (
                  <TableCell
                    key={cell.id}
                    style={{
                      width: cell.column.getSize(),
                    }}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <EmptyRow
              title="No companies being tracked"
              description="Add a company to get started"
              columns={COLUMNS.length}
            />
          )}
        </TableBody>
      </Table>
      <div className="flex items-center justify-end space-x-2 py-4">
        <Button
          variant="outline"
          size="sm"
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
          Previous
        </Button>
        <Button
          variant="outline"
          size="sm"
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        >
          Next
        </Button>
      </div>
    </div>
  );
}

export default function CompanyList() {
  const { user } = useApp();
  const data = user.companies;
  const { state } = useLocation();
  const { id, showNewCompanyForm, showNewTrackedPage } = state || {
    id: null,
    showNewCompanyForm: false,
    showNewTrackedPage: false,
  };
  const [showCompanyForm, setShowCompanyForm] = useState(showNewCompanyForm);
  const [showTrackedPageForm, setShowTrackedPageForm] = useState(showNewTrackedPage);

  const [showAddDropdown, setShowAddDropdown] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setShowCompanyForm(showNewCompanyForm);
    setShowTrackedPageForm(showNewTrackedPage);
  }, [id, showNewCompanyForm, showNewTrackedPage]);

  return (
    <ViewContainer className="pt-20 pb-10">
      <SectionHeader title="Following">
        <DropdownMenu open={showAddDropdown} onOpenChange={setShowAddDropdown}>
          <DropdownMenuTrigger>
            <PrimaryButtonContent
              onClick={() => {
                setShowAddDropdown(true);
              }}
            >
              <PlusIcon className="w-4 h-4" />
              Track new
            </PrimaryButtonContent>
          </DropdownMenuTrigger>
          <AddNewButtonContent
            didSelectAddCompany={() => {
              setShowCompanyForm(true);
            }}
            didSelectAddPage={() => {
              setShowTrackedPageForm(true);
            }}
          />
        </DropdownMenu>
      </SectionHeader>

      {data.length < 3 && (
        <div className="my-4 p-4 bg-yellow-50 border border-yellow-200 rounded-md text-sm font-medium">
          🔈 You are only tracking {data.length} {data.length === 1 ? "company" : "companies"}. Our
          users get the most out of Campana when they track ~10 companies.
        </div>
      )}

      <CompanyTableBase
        data={data}
        didClick={(company) => {
          navigate(`/following/${company.id}`);
        }}
        pageSize={100}
        columnFilters={[]}
      />

      <Dialog open={showCompanyForm} onOpenChange={setShowCompanyForm}>
        <NewCompanyDialog showURLForm={showCompanyForm} setShowURLForm={setShowCompanyForm} />
      </Dialog>

      <Dialog open={showTrackedPageForm} onOpenChange={setShowTrackedPageForm}>
        <AddURLDialog closeDialog={() => setShowTrackedPageForm(false)} />
      </Dialog>
    </ViewContainer>
  );
}
