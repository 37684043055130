import React, { useState } from "react";
import { ColumnDef, Row } from "@tanstack/react-table";
import { TrackedPageEntry } from "~/models/TrackedPage";
import useDeleteTrackedPages from "~/queries/delete-tracked-pages";
import { MoreHorizontal } from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "~/shadcn/components/ui/dropdown-menu";
import { Button } from "~/shadcn/components/ui/button";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "~/shadcn/components/ui/alert-dialog";
import { ExternalLinkIcon, GlobeIcon, TrashIcon } from "@radix-ui/react-icons";

function TrackedPageURLRow({ row }: { row: Row<TrackedPageEntry> }) {
  const [confirmDelete, setConfirmDelete] = useState(false);
  const { mutate: deleteTrackedPages } = useDeleteTrackedPages();

  return (
    <div className="flex gap-4 items-center justify-between">
      <div className="text-sm text-blue-600">{row.original.url}</div>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" className="h-8 w-8 p-0">
            <span className="sr-only">Open menu</span>
            <MoreHorizontal className="h-4 w-4" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          <DropdownMenuItem
            onClick={() => {
              window.open(row.original.url, "_blank");
            }}
          >
            <ExternalLinkIcon className="w-4 h-4 mr-4" />
            Open link
          </DropdownMenuItem>

          <DropdownMenuItem
            className="text-destructive"
            onClick={() => {
              setConfirmDelete(true);
            }}
          >
            <TrashIcon className="w-4 h-4 mr-4" />
            Remove page
          </DropdownMenuItem>
        </DropdownMenuContent>

        <AlertDialog open={confirmDelete} onOpenChange={setConfirmDelete}>
          <AlertDialogContent>
            <AlertDialogHeader>
              <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
              <AlertDialogDescription>
                This will permanently remove{" "}
                <span className="font-semibold"> "{row.original.url}" </span> from the company's
                tracked pages.
                <br />
              </AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter>
              <AlertDialogCancel>Cancel</AlertDialogCancel>
              <AlertDialogAction
                className="bg-red-600 hover:bg-red-500"
                onClick={() => {
                  deleteTrackedPages([row.original.id.toString()]);
                  setConfirmDelete(false);
                }}
              >
                Delete
              </AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      </DropdownMenu>
    </div>
  );
}

export const CompanyDetailURLManagementColumns: ColumnDef<TrackedPageEntry>[] = [
  {
    id: "url",
    accessorKey: "url",
    header: "URL",
    cell: ({ row }) => <TrackedPageURLRow row={row} />,
  },
  {
    id: "company_id",
    accessorKey: "company_id",
    header: "company_id",
    cell: ({ row }) => <div className="text-sm">{row.original.company_id}</div>,
    filterFn: "equals",
  },
];
