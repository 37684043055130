import React from "react";
import { EventType, getEventColorScheme } from "../../types/EventType";

export const EventPill = ({ type }: { type: EventType }) => {
    const [primary, secondary] = getEventColorScheme(type);

    return (
        <div style={{ backgroundColor: secondary }} className={`flex gap-[4px] items-center w-fit px-[8px] h-[24px] rounded-full`}>
            <p style={{ color: primary }} className="text-[10px] font-medium">{type}</p>
        </div>
    )
}

