import React, { useState } from "react";
import { Popover, PopoverContent, PopoverTrigger } from "~/shadcn/components/ui/popover";
import { ToolbarDiv } from "./ToolbarButton";
import { PrimaryButton } from "./PrimaryButton";
import { csrfToken } from "~/utils/helpers";
import { useMutation } from "@tanstack/react-query";
import { Check, Loader2Icon } from "lucide-react";
import { User } from "~/providers/AppProvider";

interface FeedbackButtonProps {
  user?: User;
}

export const FeedbackButton = ({ user }: FeedbackButtonProps) => {
  const [feedback, setFeedback] = useState("");

  const feedbackMutation = useMutation({
    mutationFn: (newFeedback: string) => {
      return fetch("/suggestions", {
        method: "POST",
        headers: {
          "X-CSRF-Token": csrfToken,
          "Content-Type": "application/json",
        },
        credentials: "same-origin",
        body: JSON.stringify({ content: newFeedback }),
      });
    },
  });

  return (
    <Popover>
      <PopoverTrigger>
        <ToolbarDiv>Feedback</ToolbarDiv>
      </PopoverTrigger>
      <PopoverContent>
        <div className="flex flex-col gap-2 transition-all">
          {feedbackMutation.isSuccess ? (
            <div className="flex flex-col items-center gap-4 p-2 text-center text-sm">
              <div className="p-2 bg-blue-500 rounded-full">
                <Check className="w-4 h-4 text-white" />
              </div>
              Your feedback has been received.
              <br />
              Thank you for your help!
            </div>
          ) : (
            <>
              <textarea
                value={feedback}
                onChange={(e) => setFeedback(e.target.value)}
                placeholder="Your feedback"
                className="w-full p-2 border border-slate-200 rounded-md text-xs focus:border-slate-800 focus:ring-0 h-20"
              />
              <PrimaryButton onClick={() => feedbackMutation.mutate(feedback)}>
                {feedbackMutation.isPending && <Loader2Icon className="w-3 h-3 animate-spin" />}
                Submit
              </PrimaryButton>
            </>
          )}
        </div>
      </PopoverContent>
    </Popover>
  );
};
