import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function capitalize(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export const csrfToken: string = document!
  .querySelector('meta[name="csrf-token"]')
  ?.getAttribute("content")!;

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function fetch_post(url, body) {
  return fetch(url, {
    method: "POST",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Content-Type": "application/json",
    },
    credentials: "same-origin",
    body: JSON.stringify(body),
  });
}

export function fetch_get(url) {
  return fetch(url, {
    headers: { "Content-Type": "application/json" },
    credentials: "same-origin",
  });
}

export function fetch_delete(url, body) {
  return fetch(url, {
    method: "DELETE",
    headers: {
      "X-CSRF-Token": csrfToken,
      "Content-Type": "application/json",
    },
    credentials: "same-origin",
    body: JSON.stringify(body),
  });
}
