import React from "react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "~/shadcn/components/ui/dropdown-menu";
import { TrackedPageEntry } from "~/models/TrackedPage";
import { ColumnDef } from "@tanstack/react-table";
import { PageEventTypePill } from "../ui/PageEventTypePill";
import { CaretSortIcon } from "@radix-ui/react-icons";
import { Button } from "~/shadcn/components/ui/button";
import { MoreHorizontal } from "lucide-react";
import CategoryTypePill from "../ui/CategoryTypePill";
import { URLSummaryCell } from "../ui/URLSummaryCell";

export const TrackedPagesTableColumns: ColumnDef<TrackedPageEntry>[] = [
  {
    accessorKey: "kind",
    header: "Type",
    cell: ({ row }) => {
      return <PageEventTypePill type={row.original.latest_diff ? "changed" : "unchanged"} />;
    },
    size: 80,
  },
  {
    accessorKey: "category",
    header: "Category",
    cell: ({ row }) => {
      const category = row.original.latest_diff ? row.original.latest_diff["category"] : null;
      if (category === null || category === "Unknown") {
        return <div />;
      }
      return <CategoryTypePill category={category} />;
    },
    size: 40,
    filterFn: (row, _, filterValue) => {
      const category = row.original.latest_diff ? row.original.latest_diff["category"] : null;
      return category === filterValue;
    },
  },
  {
    accessorKey: "url",
    header: "Change",
    cell: ({ row }) => {
      const url = row.getValue("url") as string;
      const summary = row.original.latest_diff ? row.original.latest_diff["summary"] : null;
      return <URLSummaryCell url={url} description={summary} />;
    },
    size: 300,
  },
  {
    accessorKey: "company_name",
    header: () => <div className="text-xs text-right">Company</div>,
    cell: ({ row }) => <div className="text-xs text-right">{row.getValue("company_name")}</div>,
    size: 120,
  },
  {
    accessorKey: "date",
    header: ({ column }) => {
      return (
        <div className="flex items-center flex-col">
          <Button
            variant="ghost"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            <div className="text-xs">Date</div>
            <CaretSortIcon className="ml-2 h-4 w-4" />
          </Button>
        </div>
      );
    },
    cell: ({ row }) => {
      const lastUpdatedDate = row.original.latest_diff ? row.original.latest_diff["date"] : null;
      if (!lastUpdatedDate) {
        return <div className="text-center text-xs text-muted-foreground">-</div>;
      }
      const relativeDate = new Date(lastUpdatedDate).toLocaleDateString(navigator.language, {
        year: "numeric",
        month: "short",
        day: "numeric",
      });
      return <div className="text-center text-xs text-muted-foreground">{relativeDate}</div>;
    },
    size: 140,
    sortingFn: (rowA, rowB) => {
      const dateA = rowA.original.latest_diff ? rowA.original.latest_diff["date"] : null;
      const dateB = rowB.original.latest_diff ? rowB.original.latest_diff["date"] : null;
      return new Date(dateA).getTime() - new Date(dateB).getTime();
    },
  },
  {
    id: "actions",
    cell: ({ row }) => {
      return (
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="ghost" className="h-8 w-8 p-0">
              <span className="sr-only">Open menu</span>
              <MoreHorizontal className="h-4 w-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuItem>Share</DropdownMenuItem>
            <DropdownMenuSeparator />
            <DropdownMenuItem>Delete</DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      );
    },
    size: 40,
  },
];
