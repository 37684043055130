import React, { useState } from "react";
import { ColumnDef, Row } from "@tanstack/react-table";
import { XIcon } from "lucide-react";
import { TrackedPageEntry } from "~/models/TrackedPage";
import { cn } from "~/utils/helpers";
import useDeleteTrackedPages from "~/queries/delete-tracked-pages";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "~/shadcn/components/ui/tooltip";

function URLRow({ row }: { row: Row<TrackedPageEntry> }) {
  const [hover, setHover] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const { mutate: deleteTrackedPages } = useDeleteTrackedPages();

  return (
    <div
      className="flex gap-4 items-center justify-between"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => {
        setHover(false);
        setConfirmDelete(false);
      }}
    >
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <span className="text-sm max-w-sm text-clip">
              {row.original.url.replace(/^(https?:\/\/)?(www\.)?/, '')}
            </span>
          </TooltipTrigger>
          <TooltipContent>
            <span>{row.original.url}</span>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
      <button
        className={cn(
          "flex transition-all p-1",
          hover ? "opacity-100" : "opacity-0",
          confirmDelete ? "bg-red-50 rounded border-red-700 border-[1px]" : "bg-transparent",
        )}
        onClick={() => {
          if (confirmDelete) {
            deleteTrackedPages([row.original.id.toString()]);
          } else {
            setConfirmDelete(true);
          }
        }}
      >
        <XIcon className="w-4 h-4 text-red-700" />
        {confirmDelete && <div className="text-red-700 text-xs ml-2">Confirm</div>}
      </button>
    </div>
  );
}

export const URLManagementTableColumns: ColumnDef<TrackedPageEntry>[] = [
  {
    id: "url",
    accessorKey: "url",
    header: "URL",
    cell: ({ row }) => <URLRow row={row} />,
  },
  {
    id: "company_id",
    accessorKey: "company_id",
    header: "company_id",
    cell: ({ row }) => <div className="text-sm">{row.original.company_id}</div>,
    filterFn: "equals",
  },
];
