import React, { useEffect, useState } from "react";
import { FilterPillButton } from "../FilterPill";
import { ColumnFiltersState } from "@tanstack/react-table";
import { ALL_CATEGORIES } from "../ui/CategoryTypePill";

export const TrackedPagesTableFilters = ({
  pages,
  setColumnFilters,
}: {
  pages: any[];
  setColumnFilters: (filters: ColumnFiltersState) => void;
}) => {
  const [companyFilter, setCompanyFilter] = useState<string | null>(null);
  const [categoryFilter, setCategoryFilter] = useState<string | null>(null);
  const companyNames = Array.from(new Set(pages.map((page) => page.company_name)));

  useEffect(() => {
    const filters: ColumnFiltersState = [];
    if (companyFilter) {
      filters.push({ id: "company_name", value: companyFilter });
    }
    if (categoryFilter) {
      filters.push({ id: "category", value: categoryFilter });
    }
    setColumnFilters(filters);
  }, [companyFilter, categoryFilter]);

  return (
    <div className="flex gap-2">
      <FilterPillButton
        title="Company"
        source={companyNames}
        filter={companyFilter}
        setFilter={setCompanyFilter}
      />
      <FilterPillButton
        title="Category"
        source={ALL_CATEGORIES}
        filter={categoryFilter}
        setFilter={setCategoryFilter}
      />
    </div>
  );
};
