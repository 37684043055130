import React from "react";
import { cn } from "~/utils/helpers";

export default function ViewContainer({ children, className }: { children: React.ReactNode; className?: string }) {
  return (
    <div className={cn("w-full overflow-y-auto flex justify-center items-start", className)}>
      <div className="flex flex-col justify-center items-start max-w-2xl content-start w-full">
        {children}
      </div>
    </div>
  );
}
