import React, { useState } from "react";
import {
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  getFilteredRowModel,
  flexRender,
} from "@tanstack/react-table";
import { ColumnFiltersState } from "@tanstack/react-table";
import useTrackedPagesForCompany from "~/queries/tracked-pages";
import { Table as ShadcnTable, TableBody, TableCell, TableRow } from "~/shadcn/components/ui/table";
import { InboxIcon } from "lucide-react";
import { TrackedPageEntry } from "~/models/TrackedPage";

export const URLManagementTable = ({ columns, companyId }: { columns: any; companyId: string }) => {
  const [rowSelection, setRowSelection] = useState({});
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [columnVisibility, setColumnVisibility] = useState({
    url: true,
    company_id: false,
  });
  const { isLoading, data } = useTrackedPagesForCompany(companyId);
  const table = useReactTable({
    data,
    columns,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onRowSelectionChange: setRowSelection,
    getRowId: (row) => row.id.toString(),
    state: {
      rowSelection,
      columnVisibility,
    },
  });

  if (isLoading) {
    return null;
  }

  return <URLManagementTableBase table={table} columnLength={columns.length} />;
};

export const URLManagementTableBase = ({
  table,
  columnLength,
}: {
  table: Table<TrackedPageEntry>;
  columnLength: number;
}) => {
  return (
    <ShadcnTable>
      <TableBody>
        {table.getRowModel().rows?.length ? (
          table.getRowModel().rows.map((row) => (
            <TableRow key={row.id} data-state={row.getIsSelected() && "selected"}>
              {row.getVisibleCells().map((cell) => (
                <TableCell key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </TableCell>
              ))}
            </TableRow>
          ))
        ) : (
          <TableRow>
            <TableCell colSpan={columnLength} className="h-24 text-center">
              <div className="flex flex-col items-center gap-2">
                <InboxIcon className="w-4 h-4 text-slate-400" />
                <span className="text-sm text-slate-400">No pages</span>
              </div>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </ShadcnTable>
  );
};
