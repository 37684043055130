import React from "react";
import { cn } from "~/utils/helpers";

interface PrimaryButtonProps {
  type?: "primary" | "secondary" | "destroy";
  enabled?: boolean;
  children: React.ReactNode;
  onClick: () => void;
}

function ColorClassNames({
  type,
  enabled,
}: {
  type: "primary" | "secondary" | "destroy";
  enabled: boolean;
}) {
  if (enabled) {
    if (type === "primary") {
      return "bg-blue-600 border-blue-700 hover:border-blue-800 hover:bg-blue-700";
    } else if (type === "secondary") {
      return "bg-white border-slate-200 hover:border-slate-300 hover:bg-slate-50 text-black";
    } else {
      return "bg-red-500 border-red-600 hover:border-red-700 hover:bg-red-600";
    }
  } else {
    return "text-slate-500 bg-slate-100";
  }
}

export const PrimaryButton = ({
  children,
  type = "primary",
  onClick,
  enabled = true,
}: PrimaryButtonProps) => {
  const color = ColorClassNames({ type, enabled });

  return (
    <button
      className={cn(
        `flex gap-2 items-center justify-center text-white text-xs px-[8px] py-[6px] rounded-md border transition-all ${color}`,
        enabled ? "shadow" : "cursor-not-allowed",
      )}
      onClick={onClick}
      disabled={!enabled}
    >
      {children}
    </button>
  );
};

export const PrimaryButtonContent = ({
  children,
  type = "primary",
  onClick,
  enabled = true,
}: PrimaryButtonProps) => {
  const color = ColorClassNames({ type, enabled });

  return (
    <div
      className={cn(
        `flex gap-2 items-center justify-center text-white text-xs px-[8px] py-[6px] rounded-md border transition-all ${color}`,
        enabled ? "shadow" : "cursor-not-allowed",
      )}
      onClick={onClick}
    >
      {children}
    </div>
  );
};
