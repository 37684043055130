import React from "react";
import { Input } from "~/shadcn/components/ui/input";
import { useApp } from "~/providers/AppProvider";
import MainContentCard from "../ui/MainContentCard";
import { SectionHeader } from "../ui/SectionHeader";
import { ToolbarButton } from "../ui/ToolbarButton";
import { MailIcon, PhoneCall } from "lucide-react";
import ColoredTextPill from "../ui/ColoredTextPill";
import { Switch } from "~/shadcn/components/ui/switch";
import { Table, TableBody, TableCell, TableRow } from "~/shadcn/components/ui/table";
import { useUpdateUserSettings, useUserSettings } from "~/queries/user-settings";

const SettingSectionHeader = ({ title }: { title: string }) => {
  return <div className="text-slate-800 mt-4 pb-4 font-semibold text-lg border-b">{title}</div>;
};

const EmailSection = () => {
  const { user } = useApp();
  return (
    <Table>
      <TableBody>
        <TableRow className="hover:bg-clear">
          <TableCell className="font-medium text-sm">Email</TableCell>
          <TableCell>
            <Input type="email" placeholder="Email" value={user?.email} disabled />
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export const ReportSettingsSection = () => {
  const { data, isLoading } = useUserSettings();
  const mutation = useUpdateUserSettings();

  if (isLoading) {
    return null;
  }

  return (
    <Table>
      <TableBody>
        <TableRow className="hover:bg-clear">
          <TableCell className="font-medium text-sm w-[500px]">
            <div className="flex flex-col gap-1">
              <div>Detect additions and deletions</div>
              <div className="text-slate-500 text-xs">Auto detect deletions and additions</div>
            </div>
          </TableCell>
          <TableCell>
            <Switch
              checked={data?.detect_additions_deletions}
              onCheckedChange={(value) => {
                mutation.mutate({ detect_additions_deletions: value });
              }}
            />
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

const PaymentSection = () => {
  const { user } = useApp();
  return (
    <Table>
      <TableBody>
        <TableRow className="hover:bg-clear">
          <TableCell className="font-medium text-sm w-[500px]">Subscription Status</TableCell>
          <TableCell>
            {user?.subscribed ? (
              <ColoredTextPill text="Active" color="green" />
            ) : (
              <ColoredTextPill text="Inactive" color="red" />
            )}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default function Settings() {
  return (
    <MainContentCard enableHorizontalPadding>
      <SectionHeader title="Settings" />

      <SettingSectionHeader title="User" />
      <EmailSection />

      <SettingSectionHeader title="Report" />
      <ReportSettingsSection />

      <SettingSectionHeader title="Payment" />
      <PaymentSection />

      <div className="flex flex-col gap-6 mb-10">
        <div className="text-slate-800 mt-4 font-medium text-lg">Support</div>

        <div className="flex flex-col items-start text-sm gap-6">
          <div className="text-slate-700">
            If you have questions or need help with the product, feel free to contact us.
          </div>
          <div className="flex gap-2">
            <ToolbarButton
              onClick={() => {
                window.open("mailto:team@studiolanes.com");
              }}
            >
              <MailIcon className="w-4 h-4" />
              Email
            </ToolbarButton>
            <ToolbarButton
              onClick={() => {
                window.open("https://cal.com/mike-choi/campana-chat");
              }}
            >
              <PhoneCall className="w-4 h-4" />
              Schedule support call
            </ToolbarButton>
          </div>
        </div>
      </div>
    </MainContentCard>
  );
}
