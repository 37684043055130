import React from "react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from "~/shadcn/components/ui/dropdown-menu";
import { BuildingIcon, GlassesIcon, MailIcon, PlusIcon } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { useSidebar } from "~/providers/SidebarProvider";

export const AddNewButtonContent = ({
  didSelectAddCompany,
  didSelectAddPage,
}: {
  didSelectAddCompany: () => void;
  didSelectAddPage: () => void;
}) => {
  return (
    <DropdownMenuContent alignOffset={20} side="bottom">
      <DropdownMenuItem
        onClick={() => {
          didSelectAddCompany();
        }}
      >
        <div className="flex items-center gap-2 text-blue-700 font-medium">
          <BuildingIcon className="w-4 h-4" />
          Track company
        </div>
      </DropdownMenuItem>
      <DropdownMenuItem
        onClick={() => {
          didSelectAddPage();
        }}
      >
        <div className="flex items-center gap-2 text-blue-700 font-medium">
          <GlassesIcon className="w-4 h-4" />
          Track new page
        </div>
      </DropdownMenuItem>
    </DropdownMenuContent>
  );
};

export const AddNewButton = () => {
  const navigate = useNavigate();
  const { setSelectedTab } = useSidebar();

  return (
    <DropdownMenu>
      <DropdownMenuTrigger>
        <div className="bg-blue-600 hover:bg-blue-700 p-[5px] rounded-full transition-all">
          <PlusIcon className="w-4 h-4 text-white" strokeWidth={2.8} />
        </div>
      </DropdownMenuTrigger>
      <AddNewButtonContent
        didSelectAddCompany={() => {
          setSelectedTab("following");
          navigate("/following", {
            state: { id: Math.random().toString(), showNewCompanyForm: true },
          });
        }}
        didSelectAddPage={() => {
          setSelectedTab("following");
          navigate("/following", {
            state: { id: Math.random().toString(), showNewTrackedPage: true },
          });
        }}
      />
    </DropdownMenu>
  );
};
