import { useMutation } from "@tanstack/react-query";
import { queryClient } from "~/utils/client";
import { fetch_delete } from "~/utils/helpers";

export default function useDeleteTrackedPages() {
  return useMutation({
    mutationFn: (ids: Array<string>) =>
      fetch_delete(`/tracked_pages/destroy_many`, { ids }).then((response) => response.json()),
    onSuccess: () => {
      ["me", "tracked_pages"].forEach((key) => {
        queryClient.invalidateQueries({ queryKey: [key] });
      });
    },
  });
}
