import React, { useState } from "react";
import { OldHeader } from "../ui/OldHeader";
import { useForm, SubmitHandler } from "react-hook-form";
import { CircleArrowRight, DiamondIcon, HexagonIcon } from "lucide-react";
import { cn, csrfToken } from "../../utils/helpers";
import { useApp } from "../../providers/AppProvider";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { useMutation } from "@tanstack/react-query";
import { Navigate } from "react-router-dom";
import { ProgressIndicator } from "~/components/ui/ProgressIndicator";
import { OnboardingNextButton } from "./OnboardingNextButton";
import { UserDetails } from "./OnboardingUserDetails";
import { Separator } from "~/shadcn/components/ui/separator";
import { CubeIcon } from "@radix-ui/react-icons";
import { FAQAccordion } from "./FAQAccordion";

const schema = z.object({
  url: z.string().url().optional(),
  role: z.string().optional(),
  companySize: z.number().optional(),
});

type Schema = z.infer<typeof schema>;

const UserCompanies = ({ setStep, trigger, register, errors, mutationError }) => {
  return (
    <div className="flex flex-col h-[200px] flex-grow justify-center">
      <label className="text-sm font-medium">Company</label>
      <input
        className="mt-2 p-2 w-full rounded-md border-[#E8E8E8] border text-sm"
        placeholder="https://openai.com"
        {...register("url", { required: true })}
      />
      {errors.url && <span className="mt-2 text-red-600 text-sm">{errors.url.message}</span>}
      {mutationError !== "" && <span className="mt-2 text-red-600 text-sm">{mutationError}</span>}
    </div>
  );
};

export const BuyButton = ({
  className,
  text,
  link,
}: {
  className?: string;
  text: string;
  link?: string;
}) => {
  return (
    <a
      className={cn(
        "bg-gradient-to-b from-[#3B7EFF] to-[#3571E4] border-[#1458DB] border px-4 text-white text-sm font-semibold rounded-md w-full p-3 mt-2 shadow-lg hover:scale-[1.01] transition-transform flex justify-between items-center",
        className,
      )}
      href={link ? link : "https://buy.stripe.com/3cs9AU7NHfLyfVSdQR"}
      target="_blank"
    >
      <span className="text-xs">{text}</span>

      <CircleArrowRight size={16} />
    </a>
  );
};

const SmallProductCard = ({ title, price, description, cta, link }) => {
  return (
    <div className="bg-[#FAFAFA] sticky top-0">
      <div className="mr-2 bg-white flex flex-col gap-1 border p-4 h-full justify-between ">
        <div className="">{title}</div>
        <div className="text-2xl">{price}</div>
        <div className="text-slate-500 text-sm">{description}</div>
        <BuyButton className="p-2 mt-4" text={cta} link={link} />
      </div>
    </div>
  );
};

const ComparisonRow = ({ title, value1, value2, value3 }) => {
  return (
    <>
      <div className="text-slate-700 col-span-2 pr-2 border-b p-2">{title}</div>
      <div className="border-b">
        <div className="bg-slate-100 mr-2 p-2 h-full">{value1}</div>
      </div>
      <div className="border-b">
        <div className="bg-slate-100 mr-2 p-2 h-full">{value2}</div>
      </div>
      <div className="border-b">
        <div className="bg-slate-100 mr-2 p-2">{value3}</div>
      </div>
    </>
  );
};

const TrialNotice = () => {
  return (
    <div className="text-xs text-slate-600 mt-4 text-left flex items-center gap-2">
      <DiamondIcon className="w-4 h-4 text-purple-500" />
      Free trial for 10 days
    </div>
  );
};

const SectionHeader = ({ title, subtitle }) => {
  return (
    <div className="flex flex-col items-start gap-2 w-full">
      <div className="text-xs py-1 px-2 text-slate-7000 bg-slate-100 font-mono">{title}</div>
      <span className="text-2xl text-left">{subtitle}</span>
    </div>
  );
};

export const UpsellPage = () => {
  return (
    <div className="flex flex-col items-center gap-14 bg-[#FAFAFA] w-full justify-center">
      <div className="max-w-5xl gap-10 flex flex-col">
        <div className="text-2xl text-left w-full mt-10">Choose a plan for your team</div>
        <div className="grid grid-cols-3 gap-2 max-w-5xl">
          <div className="flex flex-col gap-6 py-4 bg-white border rounded-sm shadow-sm max-w-md">
            <div className="flex flex-col gap-2 px-4">
              <span className="text-xl">Starter</span>
              <div className="text-slate-600 text-sm h-[60px]">
                For smaller teams, wanting to keep tabs on basic competitor activity.
              </div>
            </div>

            <div className="text-xl font-light px-4">$100 per month</div>
            <div className="px-4">
              <BuyButton text={"Get Started"} link={"https://buy.stripe.com/bIY28sec5dDq2528wI"} />
              <TrialNotice />
            </div>

            <Separator />

            <div className="flex flex-col gap-1 text-sm text-slate-600 px-4">
              <div>Track 5 companies</div>
              <div>Track new and deleted pages</div>
              <div>5 Tracked pages per company</div>
              <div>News tracking</div>
              <div>Smart categories</div>
              <div>Weekly reports</div>
              <div>Weekly highlights</div>
            </div>
          </div>

          <div className="flex flex-col gap-6 py-4 bg-white border rounded-sm shadow-sm max-w-md">
            <div className="flex flex-col gap-2 px-4">
              <div className="flex justify-between items-center">
                <span className="text-xl">Pro</span>
                <span className="text-xs bg-slate-100 rounded-sm p-1">Popular</span>
              </div>
              <div className="text-slate-600 text-sm h-[60px]">
                For teams that want an all-in-one competitive intelligence solution with full
                visibility and control.
              </div>
            </div>

            <div className="text-xl font-light px-4">$200 per month</div>
            <div className="px-4">
              <BuyButton text={"Get Started"} link={"https://buy.stripe.com/14kfZi8RL6aY8tq5kv"} />
              <TrialNotice />
            </div>

            <Separator />

            <div className="flex items-center w-full px-4 gap-2">
              <CubeIcon className="w-4 h-4 text-blue-500" />
              <span className="text-sm">Includes everything in Basic, plus</span>
            </div>

            <div className="flex flex-col gap-1 text-sm text-slate-600 px-4">
              <div>Track up to 50 companies</div>
              <div>Reddit monitoring</div>
              <div>Custom news sources</div>
              <div>Product scanner</div>
              <div>Slack integration</div>
              <div>Multi-user support</div>
            </div>
          </div>

          <div className="flex flex-col gap-6 py-4 bg-white border rounded-sm shadow-sm max-w-md">
            <div className="flex flex-col gap-2 px-4">
              <div className="flex justify-between items-center">
                <span className="text-xl">Enterprise</span>
              </div>
              <div className="text-slate-600 text-sm h-[60px]">
                For complex orgs that have regulatory, multi-user, and unique requirements.
              </div>
            </div>

            <div className="text-xl font-light px-4">Contact us</div>
            <div className="px-4">
              <BuyButton text={"Talk to Sales"} link={"https://cal.com/mike-choi/campana"} />
              <div className="h-8" />
            </div>

            <Separator />

            <div className="flex items-center w-full px-4 gap-2">
              <HexagonIcon className="w-4 h-4 text-blue-500" />
              <span className="text-sm">Includes everything in Pro, plus</span>
            </div>

            <div className="flex flex-col gap-1 text-sm text-slate-600 px-4">
              <div>Track unlimited companies</div>
              <div>Increased Storage and Retention</div>
              <div>Custom snapshot frequencies</div>
              <div>Custom tracking flows</div>
              <div>24 x 5 Support</div>
            </div>
          </div>
        </div>
      </div>

      <Separator />

      <div className="flex flex-col w-full items-center max-w-5xl gap-10">
        <SectionHeader title="COMPARISON" subtitle="See a full comparison" />

        <div className="grid grid-cols-5 w-full text-sm">
          <div className="col-span-2 bg-[#FAFAFA] w-full sticky top-0 border-b" />

          <SmallProductCard
            title="Basic"
            price="$100"
            description="per user/month"
            cta="Get Started"
            link={"https://buy.stripe.com/14kfZi8RL6aY8tq5kv"}
          />
          <SmallProductCard
            title="Pro"
            price="$200"
            description="per user/month"
            cta="Get Started"
            link={"https://buy.stripe.com/aEUfZi8RLfLyfVS9AK"}
          />
          <SmallProductCard
            title="Enterprise"
            price="Contact us"
            description=""
            cta="Talk to Sales"
            link={"https://cal.com/mike-choi/campana"}
          />

          <ComparisonRow title="Trackable Companies" value1="5" value2="50" value3="Unlimited" />
          <ComparisonRow
            title="Tracked Pages"
            value1="5 pages / company"
            value2="Unlimited"
            value3="Unlimited"
          />
          <ComparisonRow
            title="Snapshot Frequency"
            value1="24 hours"
            value2="24 hours"
            value3="Custom"
          />
          <ComparisonRow title="Added & Removed pages" value1="✓" value2="✓" value3="✓" />
          <ComparisonRow title="News" value1="✓" value2="✓" value3="✓" />
          <ComparisonRow title="Weekly Reports" value1="✓" value2="✓" value3="✓" />
          <ComparisonRow title="Weekly Highlights" value1="✓" value2="✓" value3="✓" />
          <ComparisonRow title="Smart Categories" value1="✓" value2="✓" value3="✓" />
          <ComparisonRow title="Reddit Monitoring" value1="" value2="✓" value3="✓" />
          <ComparisonRow title="Custom News Sources" value1="" value2="✓" value3="✓" />
          <ComparisonRow title="Custom Categories" value1="" value2="✓" value3="✓" />
          <ComparisonRow title="Product Scanner" value1="" value2="✓" value3="✓" />
          <ComparisonRow title="Slack Integration" value1="" value2="✓" value3="✓" />
          <ComparisonRow title="Multi-user Support" value1="" value2="✓" value3="✓" />
          <ComparisonRow title="Increased Storage and Retention" value1="" value2="" value3="✓" />
          <ComparisonRow title="Custom Website Tracking Flows" value1="" value2="" value3="✓" />
        </div>

        <SectionHeader title="FAQ" subtitle="Frequently Asked Questions" />
        <FAQAccordion />

        <SectionHeader title="CHAT" subtitle="Chat with us" />
        <div className="flex w-full gap-2 text-sm">
          <span>Have more questions?</span>
          <a href="https://cal.com/mike-choi/campana" target="_blank" className="text-blue-600">
            Schedule a chat with us here.
          </a>
        </div>
        <div className="mb-10" />
      </div>
    </div>
  );
};

const NextStep = ({ step, setStep, register, trigger, errors, mutationError }) => {
  if (step === 1) {
    return <UserDetails setStep={setStep} trigger={trigger} register={register} errors={errors} />;
  } else if (step === 2) {
    return (
      <UserCompanies
        mutationError={mutationError}
        setStep={setStep}
        trigger={trigger}
        register={register}
        errors={errors}
      />
    );
  } else {
    return <div />;
  }
};

const OnboardingComponent = ({ step, setStep }) => {
  const { register, handleSubmit, formState, trigger } = useForm<Schema>({
    resolver: zodResolver(schema),
  });
  const [mutationError, setMutationError] = useState<string>("");
  const mutation = useMutation({
    mutationFn: (data: Schema) => {
      return fetch("/onboarding", {
        method: "POST",
        headers: {
          "X-CSRF-Token": csrfToken,
          "Content-Type": "application/json",
        },
        credentials: "same-origin",
        body: JSON.stringify({ data }),
      })
        .then(async (response) => {
          if (!response.ok) {
            const result = await response.json();
            setMutationError(result.error);
          } else {
            setStep(3);
          }
        })
        .catch((error) => {
          console.error("Onboarding error", error);
        });
    },
  });

  const onSubmit: SubmitHandler<Schema> = async (data) => {
    const isStepValid = await trigger();
    if (!isStepValid) {
      return;
    }

    if (step == 1) {
      setStep(step + 1);
      return;
    } else {
      setStep(step + 1);
      return mutation.mutate(data);
    }
  };

  return (
    <div className="flex justify-center items-center bg-[#FAFAFA] h-[calc(100vh-80px)]">
      <div className="flex flex-col p-6 gap-4 bg-white rounded-xl w-[480px] min-h-[400px] border-slate-200 border justify-between">
        <div className="flex flex-col gap-4">
          <ProgressIndicator step={step} totalSteps={3} />
          {step === 1 && (
            <span className="font-medium">
              Nice to meet you 👋 <br></br>Tell us a bit about yourself
            </span>
          )}
          {step === 2 && (
            <span className="font-medium">Which company do you want to keep tabs on?</span>
          )}
        </div>

        <form
          onSubmit={handleSubmit(onSubmit)}
          autoComplete="off"
          className="flex flex-col justify-center"
        >
          <NextStep
            step={step}
            setStep={setStep}
            trigger={trigger}
            register={register}
            errors={formState.errors}
            mutationError={mutationError}
          />
          <div className="flex-grow"></div>
          <OnboardingNextButton type="submit" />
        </form>
      </div>
    </div>
  );
};

export const Onboarding = () => {
  const { user } = useApp();
  const [step, setStep] = useState(() => (user?.onboarded ? 3 : 1));

  if (user?.onboarded && user?.subscribed) {
    return <Navigate to="/" replace={true} />;
  } else {
    return (
      <>
        <OldHeader />
        {step == 3 ? <UpsellPage /> : <OnboardingComponent step={step} setStep={setStep} />}
      </>
    );
  }
};
