import React from "react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "~/shadcn/components/ui/dropdown-menu";
import { MailIcon, MessageCircleQuestionIcon, PhoneIcon } from "lucide-react";

export const HelpDropdownButton = () => {
  return (
    <DropdownMenu >
      <DropdownMenuTrigger>
        <MessageCircleQuestionIcon className="w-5 h-5" />
      </DropdownMenuTrigger>
      <DropdownMenuContent
        sideOffset={10}
        alignOffset={8}
        className="w-fit"
      >
        <DropdownMenuItem onClick={() => window.open("mailto:team@studiolanes.com")}>
          <div className="flex items-center gap-2">
            <MailIcon className="w-4 h-4" />
            Contact us
          </div>
        </DropdownMenuItem>
        <DropdownMenuItem onClick={() => window.open("https://cal.com/mike-choi/campana-chat")}>
          <div className="flex items-center gap-2">
            <PhoneIcon className="w-4 h-4" />
            Schedule call
          </div>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
