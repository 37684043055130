import { useMutation } from "@tanstack/react-query";
import { queryClient } from "~/utils/client";
import { fetch_post } from "~/utils/helpers";

export default function useAddTrackedPages(
  onSuccess: () => void,
  onError: (error?: string) => void,
) {
  return useMutation({
    mutationFn: ({ companyId, url }: { companyId: string; url: string }) => {
      return fetch_post("/tracked_pages", { companyId, url }).then(async (response) => {
        const json = await response.json();
        if (response.status == 400) {
          throw new Error(json.error);
        }
        return json;
      });
    },
    onSuccess: () => {
      ["me", "tracked_pages"].forEach((key) => {
        queryClient.invalidateQueries({ queryKey: [key] });
      });
      onSuccess();
    },
    onError: (error?: string) => {
      onError(error);
    },
  });
}
