import React, { useEffect, useState } from "react";
import MainContentCard from "../ui/MainContentCard";
import { SectionHeader } from "../ui/SectionHeader";
import { InfoCircledIcon } from "@radix-ui/react-icons";
import { FilterPillButton } from "../FilterPill";
import { TrackedPageEntry } from "~/models/TrackedPage";
import { useQuery } from "@tanstack/react-query";
import { fetch_get } from "~/utils/helpers";
import { HoverCard, HoverCardContent, HoverCardTrigger } from "~/shadcn/components/ui/hover-card";
import { AddTrackedPagesButton } from "./AddTrackedPagesButton";
import { ManageTrackedPagesButton } from "../pageDiff/WebsiteMonitoring";
import { TrackedPagesTable } from "./TrackedPagesTable";
import { ColumnFiltersState } from "@tanstack/react-table";
import { TrackedPagesTableFilters } from "./TrackedPagesTableFilters";

const PageMonitoringBaseView = ({ data }: { data: TrackedPageEntry[] }) => {
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

  return (
    <MainContentCard enableHorizontalPadding={true}>
      <div className="flex items-center gap-2 mb-2 w-fit">
        <SectionHeader title="Tracked Pages" />
        <HoverCard>
          <HoverCardTrigger>
            <InfoCircledIcon className="w-4 h-4 text-slate-500" />
          </HoverCardTrigger>
          <HoverCardContent>
            <div className="text-sm">
              <strong>What are tracked pages?</strong>
              <br />
              These are pages you wish to track for visual changes in the content.
            </div>
          </HoverCardContent>
        </HoverCard>
      </div>

      <div className="flex items-center justify-between gap-2">
        <TrackedPagesTableFilters pages={data} setColumnFilters={setColumnFilters} />
       
        <div className="w-full" />
        <AddTrackedPagesButton />
        <ManageTrackedPagesButton />
      </div>

      <TrackedPagesTable
        data={data}
        columnFilters={columnFilters}
        setColumnFilters={setColumnFilters}
      />
    </MainContentCard>
  );
};

export const TrackedPagesView = () => {
  const { isLoading, data } = useQuery({
    queryKey: ["tracked_pages"],
    queryFn: () => fetch_get("/tracked_pages").then((response) => response.json()),
  });

  if (isLoading) {
    return null;
  }

  return <PageMonitoringBaseView data={data || []} />;
};
