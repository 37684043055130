import { useMutation } from "@tanstack/react-query";
import { queryClient } from "~/utils/client";
import { fetch_delete } from "~/utils/helpers";

export default function useDeleteQuestion(companyId: string) {
  return useMutation({
    mutationFn: (id: string) =>
      fetch_delete(`/companies/${companyId}/questions/${id}`, {}).then((response) =>
        response.json(),
      ),
    onSuccess: () => {
      ["companies", companyId, "questions"].forEach((key) => {
        queryClient.invalidateQueries({ queryKey: [key] });
      });
    },
  });
}
