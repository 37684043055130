import React from "react";

//@ts-ignore
import ReportUnselected from "~/images/report.svg";
//@ts-ignore
import ReportSelected from "~/images/report-selected.svg";
//@ts-ignore
import PageTrackUnselected from "~/images/page-track.svg";
//@ts-ignore
import PageTrackSelected from "~/images/page-track-selected.svg";
//@ts-ignore
import SocialUnselected from "~/images/social.svg";
//@ts-ignore
import SocialSelected from "~/images/social-selected.svg";
//@ts-ignore
import FollowingUnselected from "~/images/following.svg";
//@ts-ignore
import FollowingSelected from "~/images/following-selected.svg";
//@ts-ignore
import WebsiteMonitoringUnselected from "~/images/website.svg";
//@ts-ignore
import WebsiteMonitoringSelected from "~/images/website-selected.svg";

export const TAB_ITEMS = [
  {
    selected: "",
    logo: <img src={ReportUnselected} alt="Report" />,
    selectedLogo: <img src={ReportSelected} alt="Report selected" />,
    area: "Reports",
  },
  {
    selected: "website-monitoring",
    logo: <img src={WebsiteMonitoringUnselected} alt="Website Monitoring" />,
    selectedLogo: <img src={WebsiteMonitoringSelected} alt="Website Monitoring selected" />,
    area: "Website Monitoring",
  },
  {
    selected: "page-changes",
    logo: <img src={PageTrackUnselected} alt="Page Track" />,
    selectedLogo: <img src={PageTrackSelected} alt="Page Track selected" />,
    area: "Tracked Pages",
  },
  { 
    selected: "news",
    logo: <img src={SocialUnselected} alt="Social" />,
    selectedLogo: <img src={SocialSelected} alt="Social selected" />,
    area: "News",
  },
  { 
    selected: "following",
    logo: <img src={FollowingUnselected} alt="Following" />,
    selectedLogo: <img src={FollowingSelected} alt="Following selected" />,
    area: "Following",
  },
];
