import React from "react";

interface SectionHeaderProps {
  title: string;
  children?: React.ReactNode;
}

export const SectionHeader = ({ title, children }: SectionHeaderProps) => {
  return (
    <div className="flex items-center justify-between w-full my-4">
      <h2 className="text-xl font-medium">{title}</h2>
      {children}
    </div>
  );
};
