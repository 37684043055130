import { useQuery } from "@tanstack/react-query";
import { fetch_get } from "~/utils/helpers";

export default function useRecommendedCompanyTrackedPages(companyId: string) {
  return useQuery({
    queryKey: ["tracked_pages", "page_recs", companyId],
    queryFn: () =>
      fetch_get(`/companies/${companyId}/page_recs`).then((response) => response.json()),
  });
}
