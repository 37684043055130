import React, { useState } from "react";
import {
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { toast } from "sonner";
import { WebsiteMonitoringTableColumns } from "./WebsiteMonitoringColumns";
import { PageEventTableBase } from "../ui/PageEventTableBase";

export const useWebsiteMonitoringTable = (data, columnFilters, setColumnFilters) => {
  const [sorting, setSorting] = useState<SortingState>([{ id: "date", desc: true }]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  return useReactTable({
    data,
    columns: WebsiteMonitoringTableColumns,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    onColumnFiltersChange: setColumnFilters,
    defaultColumn: {
      size: 100,
      minSize: 50,
      maxSize: 2000,
    },
    state: {
      pagination,
      columnFilters,
      sorting,
    },
  });
};

export const WebsiteMonitoringTable = ({ data, columnFilters, setColumnFilters }) => {
  const table = useWebsiteMonitoringTable(data, columnFilters, setColumnFilters);

  return (
    <PageEventTableBase
      table={table}
      didClickRow={(row) => {
        const pageChange = data.find((page) => page.id === row.id);

        if (pageChange.kind == "addition") {
          window.open(pageChange.url, "_blank");
        } else if (pageChange.kind == "deletion") {
          toast("👻 Deleted pages are not viewable");
        } else {
          toast("💣 Something went wrong");
        }
      }}
    />
  );
};
