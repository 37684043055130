import { useQuery } from "@tanstack/react-query";
import { fetch_get } from "~/utils/helpers";

export default function useQuestionsForCompany(companyId: string) {
  return useQuery({
    queryKey: ["companies", companyId, "questions"],
    queryFn: () =>
      fetch_get(`/companies/${companyId}/questions`).then((response) => response.json()),
  });
}
