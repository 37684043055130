// Example: Load Rails libraries in Vite.
//
// import * as Turbo from '@hotwired/turbo'
// Turbo.start()
//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'

import React from "react";
import { createRoot } from "react-dom/client";
import { App } from "../components/App";
import * as Sentry from "@sentry/react";

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    // Performance Monitoring
    tracesSampleRate: 0.1,
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    debug: process.env.NODE_ENV !== "production",
  });
}

document.addEventListener("DOMContentLoaded", () => {
  const element = document.getElementById("react");
  const initialUser = window.me;
  if (element) {
    if (process.env.NODE_ENV === "production" && !initialUser.isSudo) {
      // This only really exists in production
      window.posthog?.identify(`${initialUser.id}`, {
        email: initialUser.email,
        name: initialUser.displayName,
      });
    }
    const root = createRoot(element);
    root.render(<App initialUser={initialUser} />);
  }
});
