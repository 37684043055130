import React, { createContext, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

interface SidebarContextProps {
  selectedTab: string;
  setSelectedTab: (tab: string) => void;
  selectAndNavigateToTab: (tab: string) => void;
}

export const SidebarContext = createContext<SidebarContextProps>({
  selectedTab: "",
  setSelectedTab: (tab: string) => {},
  selectAndNavigateToTab: (tab: string) => {},
});

export const SidebarProvider = ({ children }: { children: React.ReactNode }) => {
  const [selectedTab, setSelectedTab] = useState("");
  const navigate = useNavigate();

  function selectAndNavigateToTab(tab: string) {
    setSelectedTab(tab);
    navigate(`./${tab.toLowerCase()}`);
  }

  return (
    <SidebarContext.Provider
      value={{
        selectedTab,
        setSelectedTab,
        selectAndNavigateToTab,
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
};

export const useSidebar = () => {
  return useContext(SidebarContext);
};
