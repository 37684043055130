import React from "react";
import ColoredTextPill from "./ColoredTextPill";

export function PageEventTypePill({ type }: { type: string }) {
  let color;
  let text;

  if (type === "changed") {
    color = "blue";
    text = "Changed";
  } else if (type === "addition") {
    color = "green";
    text = "Added";
  } else if (type === "deletion") {
    color = "red";
    text = "Removed";
  } else {
    color = "gray";
    text = type;
  }

  return <ColoredTextPill text={text} color={color} />;
}
