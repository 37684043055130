import React from "react";
import ColoredTextPill from "./ColoredTextPill";

export const ALL_CATEGORIES = [
  "product",
  "funding",
  "people",
  "testimonials",
  "partnership",
  "events",
];

export default function CategoryTypePill({ category }: { category?: string }) {
  let color;
  if (category === "product") {
    color = "purple";
  } else if (category === "funding") {
    color = "indigo";
  } else if (category === "people") {
    color = "orange";
  } else if (category === "testimonials") {
    color = "pink";
  } else if (category === "partnership") {
    color = "yellow";
  } else if (category === "events") {
    color = "red";
  } else {
    color = "gray";
  }

  return <ColoredTextPill text={category ?? "Unknown"} color={color} />;
}
