import React, { useEffect } from "react";

interface ToolbarButtonProps {
  onClick?: () => void;
  children: React.ReactNode;
  listenKey?: string;
  disabled?: boolean;
}

export function ToolbarDiv({ onClick, children, listenKey, disabled = false }: ToolbarButtonProps) {
  useEffect(() => {
    if (disabled) return;

    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === listenKey) {
        onClick();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [onClick, listenKey]);

  return (
    <div
      className="w-fit h-fit flex border gap-2 rounded-lg px-2 py-[6px] shadow-sm hover:border-slate-500 transition-all bg-white text-xs whitespace-nowrap outline-none"
      onClick={onClick}
      tabIndex={0}
    >
      {children}
    </div>
  );
}

export function ToolbarButton({
  onClick,
  children,
  listenKey,
  disabled = false,
}: ToolbarButtonProps) {
  useEffect(() => {
    if (disabled) return;

    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === listenKey) {
        onClick();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [onClick, listenKey]);

  return (
    <button
      className="w-fit h-fit flex border gap-2 rounded-lg px-2 py-[6px] shadow-sm hover:border-slate-500 transition-all bg-white text-xs whitespace-nowrap outline-none focus:outline-none focus:ring-0"
      onClick={onClick}
      tabIndex={0}
    >
      {children}
    </button>
  );
}
