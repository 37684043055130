import React, { useState } from "react";
import {
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  flexRender,
  ColumnDef,
  Row,
  Table,
} from "@tanstack/react-table";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "~/shadcn/components/ui/dropdown-menu";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "~/shadcn/components/ui/alert-dialog";
import { Table as ShadcnTable, TableBody, TableCell, TableRow } from "~/shadcn/components/ui/table";
import { Button } from "~/shadcn/components/ui/button";
import { InboxIcon, MoreHorizontal } from "lucide-react";
import useQuestionsForCompany from "~/queries/questions-user-companies";
import useDeleteQuestion from "~/queries/delete-questions";
import { Question } from "~/models/Question";
import { TrashIcon } from "@radix-ui/react-icons";

function QuestionRow({ row }: { row: Row<Question> }) {
  const [confirmDelete, setConfirmDelete] = useState(false);
  const { mutate: deleteQuestion } = useDeleteQuestion(row.original.user_company_id.toString());

  return (
    <div className="flex justify-between items-center">
      <span className="w-full text-left">{row.original.question}</span>

      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" className="h-8 w-8 p-0">
            <MoreHorizontal className="h-4 w-4" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          <DropdownMenuItem className="text-destructive" onClick={() => setConfirmDelete(true)}>
            <TrashIcon className="w-4 h-4 mr-2" />
            Delete
          </DropdownMenuItem>
        </DropdownMenuContent>

        <AlertDialog open={confirmDelete} onOpenChange={setConfirmDelete}>
          <AlertDialogContent>
            <AlertDialogHeader>
              <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
              <AlertDialogDescription>
                This will permanently remove the question.
              </AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter>
              <AlertDialogCancel>Cancel</AlertDialogCancel>
              <AlertDialogAction
                className="bg-red-600 hover:bg-red-500"
                onClick={() => {
                  deleteQuestion(row.original.id.toString());
                  setConfirmDelete(false);
                }}
              >
                Delete
              </AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      </DropdownMenu>
    </div>
  );
}

const QuestionsTableColumns: ColumnDef<Question>[] = [
  {
    accessorKey: "question",
    header: "URL",
    cell: ({ row }) => {
      return <QuestionRow row={row} />;
    },
  },
];

const QuestionsTableBase = ({ table }: { table: Table<Question> }) => {
  return (
    <ShadcnTable>
      <TableBody>
        {table.getRowModel().rows?.length ? (
          table.getRowModel().rows.map((row) => (
            <TableRow key={row.id} data-state={row.getIsSelected() && "selected"}>
              {row.getVisibleCells().map((cell) => (
                <TableCell key={cell.id} style={{ width: cell.column.getSize() }}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </TableCell>
              ))}
            </TableRow>
          ))
        ) : (
          <TableRow>
            <TableCell colSpan={2} className="h-24 text-center">
              <div className="flex flex-col items-center gap-2">
                <InboxIcon className="w-4 h-4 text-slate-400" />
                <span className="text-sm text-slate-400">No questions</span>
              </div>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </ShadcnTable>
  );
};

export const QuestionsTable = ({ companyId }: { companyId: string }) => {
  const [rowSelection, setRowSelection] = useState({});
  const [columnVisibility, setColumnVisibility] = useState({
    url: true,
    company_id: false,
  });
  const { isLoading, data } = useQuestionsForCompany(companyId);
  const questions = data?.questions || [];

  const table = useReactTable({
    data: questions,
    columns: QuestionsTableColumns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getRowId: (row) => row.id.toString(),
    state: {
      rowSelection,
      columnVisibility,
    },
  });

  if (isLoading) {
    return null;
  }

  return <QuestionsTableBase table={table} />;
};
