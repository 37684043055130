import React from "react";
import { cn } from "~/utils/helpers";

export default function ColoredTextPill({
  text,
  color,
}: {
  text: string;
  color: "green" | "blue" | "red" | "gray" | "purple" | "orange" | "pink" | "indigo" | "zinc" | "lime" | "yellow";
}) {
  let colorClasses;
  if (color === "green") {
    colorClasses = "text-[#217007] bg-[#D1FAB3] border-[#A8F16F]";
  } else if (color === "blue") {
    colorClasses = "text-[#045AD0] bg-[#CBF5FD] border-[#A8E7FC]";
  } else if (color === "red") {
    colorClasses = "text-red-600 bg-red-50 border-red-300";
  } else if (color == "purple") {
    colorClasses = "text-purple-600 bg-purple-50 border-purple-300";
  } else if (color == "orange") {
    colorClasses = "text-orange-600 bg-orange-50 border-orange-300";
  } else if (color == "pink") {
    colorClasses = "text-pink-600 bg-pink-50 border-pink-300";
  } else if (color == "indigo") {
    colorClasses = "text-indigo-600 bg-indigo-50 border-indigo-300";
  } else if (color == "zinc") {
    colorClasses = "text-zinc-600 bg-zinc-50 border-zinc-300";
  } else if (color == "lime") {
    colorClasses = "text-lime-6000 bg-lime-50 border-lime-300";
  } else if (color == "yellow") {
    colorClasses = "text-yellow-600 bg-yellow-50 border-yellow-300";
  } else {
    colorClasses = "text-gray-600 bg-gray-50 border-gray-300";
  }

  return (
    <div
      className={cn(
        "capitalize text-[11px] px-[4px] border w-fit rounded-lg font-semibold",
        colorClasses,
      )}
    >
      {text}
    </div>
  );
}
