import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { ToolbarDiv } from "../ui/ToolbarButton";
import { BuildingIcon } from "lucide-react";
import {
  DialogTitle,
  Dialog,
  DialogTrigger,
  DialogContent,
  DialogHeader,
  DialogDescription,
} from "~/shadcn/components/ui/dialog";
import { fetch_get } from "~/utils/helpers";

export const ReportCompanyListButton = ({ reportId }: { reportId: string }) => {
  const [showCompanyListDialog, setShowCompanyListDialog] = useState(false);
  const { isLoading, data } = useQuery({
    queryKey: ["report", reportId],
    queryFn: () => {
      return fetch_get(`/api/reports/${reportId}`).then((response) => response.json());
    },
  });

  return (
    <Dialog open={showCompanyListDialog} onOpenChange={setShowCompanyListDialog}>
      <DialogTrigger>
        <ToolbarDiv>
          <BuildingIcon className="w-4 h-4" />
          Tracked companies
        </ToolbarDiv>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Tracked Companies</DialogTitle>
          <DialogDescription>
            If you want to add or remove companies, go to the Companies tab.
          </DialogDescription>
        </DialogHeader>
        <div className="flex flex-col gap-2 overflow-y-auto">
          {data?.company_urls.map((url, index) => (
            <div className="flex gap-2 items-center" key={index}>
              <img
                src={`https://logo.clearbit.com/${url}`}
                className="w-5 h-5 rounded border shadow-sm"
              />
              <div className="text-sm">{url}</div>
            </div>
          ))}
        </div>
      </DialogContent>
    </Dialog>
  );
};
