import React, { useState } from "react";
import {
  ColumnFiltersState,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { TrackedPageEntry } from "~/models/TrackedPage";
import { TrackedPagesTableColumns } from "./TrackedPagesTableColumns";
import { PageEventTableBase } from "../ui/PageEventTableBase";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";

export const useTrackedPagesTable = (
  data: TrackedPageEntry[],
  columnFilters: ColumnFiltersState,
  setColumnFilters: (columnFilters: ColumnFiltersState) => void,
) => {
  const [sorting, setSorting] = useState<SortingState>([{ id: "date", desc: true }]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  return useReactTable({
    data,
    columns: TrackedPagesTableColumns,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getSortedRowModel: getSortedRowModel(),
    defaultColumn: {
      size: 200,
      minSize: 50,
      maxSize: 2000,
    },
    state: {
      pagination,
      columnFilters,
      sorting,
    },
  });
};

export const TrackedPagesTable = ({
  data,
  columnFilters,
  setColumnFilters,
}: {
  data: TrackedPageEntry[];
  columnFilters: ColumnFiltersState;
  setColumnFilters: (columnFilters: ColumnFiltersState) => void;
}) => {
  const table = useTrackedPagesTable(data, columnFilters, setColumnFilters);
  const navigate = useNavigate();

  return (
    <PageEventTableBase
      table={table}
      didClickRow={(row) => {
        if (row.latest_diff) {
          navigate({
            pathname: `/page-changes/${row.id}`,
          });
        } else {
          toast("⏳ No change has been detected for this page yet");
        }
      }}
    />
  );
};
