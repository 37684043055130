import React, { useState } from "react";
import {
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "~/shadcn/components/ui/dialog";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "~/shadcn/components/ui/select";
import { PrimaryButton } from "../ui/PrimaryButton";
import { Input } from "~/shadcn/components/ui/input";
import { useApp } from "~/providers/AppProvider";
import { toast } from "sonner"
import { queryClient } from "~/utils/client";
import { useMutation } from "@tanstack/react-query";
import { fetch_post } from "~/utils/helpers";

interface AddURLDialogProps {
  targetCompanyId?: number;
  closeDialog?: () => void;
}

export const AddURLDialog = ({ targetCompanyId, closeDialog }: AddURLDialogProps) => {
  const { user } = useApp();
  const [companyId, setCompanyId] = useState<number | null>(
    targetCompanyId || user.companies[0]?.id || null,
  );
  const targetCompany = user.companies.find((company) => company.id === companyId);
  const [url, setUrl] = useState("");

  const mutation = useMutation({
    mutationFn: ({ companyId, url }: { companyId: number; url: string }) => {
      return fetch_post("/tracked_pages", { companyId, url }).then(async (response) => {
        const json = await response.json();
        if (response.status == 400) {
          throw new Error(json.error);
        }
        return json;
      });
    },
    onSuccess: () => {
      ["me", "tracked_pages"].forEach((queryKey) => {
        queryClient.invalidateQueries({ queryKey: [queryKey] });
      });

      closeDialog && closeDialog();
    },
  });

  return (
    <DialogContent className="sm:max-w-[425px]">
      <DialogHeader>
        <DialogTitle>Track new {targetCompany?.name} page</DialogTitle>
        <DialogDescription>
          Enter a URL related to {targetCompany?.name} to monitor for text and image updates
        </DialogDescription>
      </DialogHeader>

      <div className="flex flex-col gap-2 items-start w-full my-4">
        <div className="text-xs font-semibold text-slate-700">URL</div>
        <Input
          id="url"
          placeholder={`${targetCompany?.url}/page`}
          value={url}
          onChange={(e) => setUrl(e.target.value)}
        />

        {!targetCompanyId && (
          <>
            <div className="text-xs font-semibold text-slate-700 mt-4">For company</div>
            <Select onValueChange={(value) => setCompanyId(value)} value={companyId}>
              <SelectTrigger className="w-full">
                <SelectValue placeholder="Company" />
              </SelectTrigger>
              <SelectContent>
                {user.companies.map((company) => {
                  return (
                    <SelectItem key={company.id} value={company.id}>
                      {company.name}
                    </SelectItem>
                  );
                })}
              </SelectContent>
            </Select>
          </>
        )}
      </div>
      <PrimaryButton
        onClick={() => {
          mutation.mutate(
            { companyId, url },
            {
              onSuccess: () => {
                setUrl("");

                toast("🕶️ Page tracking started", {
                  description: "You will be notified when the page changes",
                });
              },
              onError: (error) => {
                toast(error.message, {
                  description: "Please fix the issue above",
                });
              },
            },
          );
        }}
        enabled={!mutation.isPending}
      >
        Start Tracking
      </PrimaryButton>
    </DialogContent>
  );
};
