import React from "react";

export const URLSummaryCell = ({ url, description }: { url: string; description?: string }) => {
  const formattedUrl = url.replace(/(^\w+:|^)\/\//, "").replace("www.", "");

  return description ? (
    <div className="flex flex-col items-start gap-2 my-2 text-left">
      <div className="text-xs italic">{description}</div>
      <div className="text-xs text-muted-foreground">{formattedUrl}</div>
    </div>
  ) : (
    <div className="text-xs text-left text-muted-foreground">{formattedUrl}</div>
  );
};
