import React from "react";

export const UserDetails = ({ setStep, trigger, register, errors }) => {
  return (
    <div className="flex flex-col gap-4 w-full">
      <div className="flex flex-col">
        <label className="text-sm font-medium">
          Role <span className="text-red-600">*</span>
        </label>
        <input
          className="mt-2 p-2 w-full rounded-md border-[#E8E8E8] border text-sm"
          placeholder="e.g. Director of Marketing"
          {...register("role", { required: true })}
        />
        {errors.role && <span className="mt-2 text-red-600 text-sm">{errors.role.message}</span>}
      </div>
      <div className="flex flex-col">
        <label className="text-sm">
          Company Size <span className="text-red-600">*</span>
        </label>
        <input
          className="mt-2 p-2 w-full rounded-md border-[#E8E8E8] border text-sm"
          placeholder="57"
          type="number"
          {...register("companySize", { required: true, valueAsNumber: true })}
        />

        {/* errors will return when field validation fails  */}
        {errors.companySize && (
          <span className="mt-2 text-red-600 text-sm">{errors.companySize.message}</span>
        )}
      </div>
      <div className="h-full" />
    </div>
  );
};
