import React, { useEffect, useState } from "react";
import { FilterPillButton } from "../FilterPill";
import { TrackedPageDiff } from "~/models/TrackedPage";
import { ColumnFiltersState } from "@tanstack/react-table";
import { ALL_CATEGORIES } from "../ui/CategoryTypePill";

export const WebsiteMonitoringFilters = ({
  diffs,
  setColumnFilters,
}: {
  diffs: TrackedPageDiff[];
  setColumnFilters: (filters: ColumnFiltersState) => void;
}) => {
  const [companyFilter, setCompanyFilter] = useState<string | null>(null);
  const [typeFilter, setTypeFilter] = useState<string | null>(null);
  const [categoryFilter, setCategoryFilter] = useState<string | null>(null);

  const companyNames = Array.from(
    new Set(diffs.map((page) => page.company_name).filter((name) => name)),
  );

  useEffect(() => {
    const filters: ColumnFiltersState = [];
    if (companyFilter) {
      filters.push({ id: "company_name", value: companyFilter });
    }
    if (typeFilter) {
      filters.push({ id: "kind", value: typeFilter });
    }
    if (categoryFilter) {
      filters.push({ id: "category", value: categoryFilter });
    }
    setColumnFilters(filters);
  }, [companyFilter, typeFilter, categoryFilter]);

  return (
    <div className="flex gap-2">
      <FilterPillButton
        title="Company"
        source={companyNames}
        filter={companyFilter}
        setFilter={setCompanyFilter}
      />
      <FilterPillButton
        title="Event"
        source={["addition", "deletion"]}
        filter={typeFilter}
        setFilter={setTypeFilter}
      />
      <FilterPillButton
        title="Category"
        source={ALL_CATEGORIES}
        filter={categoryFilter}
        setFilter={setCategoryFilter}
      />
    </div>
  );
};
