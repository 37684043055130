import React, { useState } from "react";
import MainContentCard from "../ui/MainContentCard";
import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { cn, fetch_get } from "~/utils/helpers";
import { NewsTable } from "../news/NewsView";
import { Loader2 } from "lucide-react";
import { useParams } from "react-router-dom";
import { humanizedReportDateString } from "~/models/Report";
import { WebsiteMonitoringTable } from "../pageDiff/WebsiteMonitoringTable";
import { ReportCompanyListButton } from "./ReportCompanyListButton";
import { HighlightCell } from "./HighlightCell";
import { ColumnFiltersState } from "@tanstack/react-table";
import { TrackedPageDiffTable } from "./TrackedPageDiffTable";
import { toast } from "sonner";
//@ts-ignore
import { WebsiteMonitoringFilters } from "../pageDiff/WebsiteMonitoringFilters";
import { TrackedPagesTableFilters } from "../trackedPages/TrackedPagesTableFilters";
import { Insight } from "~/models/Insight";
import { useApp } from "~/providers/AppProvider";
import { ToolbarButton } from "../ui/ToolbarButton";
import { CopyIcon } from "@radix-ui/react-icons";
import { AnswerCell } from "./AnswerCell";

const SectionHeader = ({ title }: { title: string }) => {
  return <div className="w-full font-semibold mb-4">{title}</div>;
};

const AdditionsDeletionsSection = ({ query }: { query: UseQueryResult<any, Error> }) => {
  const { isLoading, data } = query;
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

  return (
    <div className="w-full px-16" id="page-changes-section">
      <SectionHeader title="Additions & Deletions" />
      <WebsiteMonitoringFilters diffs={data?.diffs ?? []} setColumnFilters={setColumnFilters} />

      <WebsiteMonitoringTable
        data={data?.diffs ?? []}
        columnFilters={columnFilters}
        setColumnFilters={setColumnFilters}
      />
    </div>
  );
};

const PageChangesSection = ({ query }: { query: UseQueryResult<any, Error> }) => {
  const { isLoading, data } = query;
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

  return (
    <div className="w-full px-16" id="page-changes-section">
      <SectionHeader title="Changed Pages" />
      <TrackedPagesTableFilters pages={data?.diffs || []} setColumnFilters={setColumnFilters} />
      <TrackedPageDiffTable
        data={data?.diffs || []}
        columnFilters={columnFilters}
        setColumnFilters={setColumnFilters}
      />
    </div>
  );
};

export const NewsSection = ({ query }: { query: UseQueryResult<any, Error> }) => {
  const { isLoading, data } = query;
  return (
    <div className="w-full px-16" id="news-section">
      <div className="w-full font-semibold">News</div>
      <NewsTable data={data || []} pageSize={4} />
    </div>
  );
};

const EventStatsCell = ({
  title,
  count,
  isActive,
  onClick,
}: {
  title: string;
  count?: number;
  isActive: boolean;
  onClick: () => void;
}) => {
  return (
    <button
      className={cn(
        "flex flex-col border p-3 rounded-xl gap-2 w-fit items-start min-w-[200px]",
        isActive ? "border-[#3B7EFF] shadow-sm shadow-[#3B7EFF]/20" : "",
      )}
      onClick={onClick}
    >
      <div className={cn("font-semibold text-xs", isActive ? "text-[#3B7EFF]" : "text-slate-600")}>
        {title}
      </div>
      <div className={cn("font-semibold text-sm", isActive ? "text-[#3B7EFF]" : "text-slate-700")}>
        {count === null ? <Loader2 className="w-4 h-4 transition-all animate-spin" /> : count}
      </div>
    </button>
  );
};

export const ReportsDetailView = () => {
  const { reportId } = useParams();

  const { isLoading, data } = useQuery({
    queryKey: ["report", reportId],
    queryFn: () => {
      return fetch_get(`/api/reports/${reportId}`).then((response) => response.json());
    },
  });

  const pageMonitoringQuery = useQuery({
    queryKey: ["page_monitoring", reportId],
    queryFn: () =>
      fetch_get(`/api/reports/${reportId}/page_monitoring`).then((response) => response.json()),
  });

  const trackedPageMonitoringQuery = useQuery({
    queryKey: ["tracked_page_monitoring", reportId],
    queryFn: () =>
      fetch_get(`/api/reports/${reportId}/tracked_page_monitoring`).then((response) =>
        response.json(),
      ),
  });

  const companyArticlesQuery = useQuery({
    queryKey: ["company_articles", reportId],
    queryFn: () =>
      fetch_get(`/api/reports/${reportId}/company_articles`).then((response) => response.json()),
  });

  const [filter, setFilter] = useState<"all" | "website_monitoring" | "page_monitoring" | "news">(
    "all",
  );
  const pageDiffs = pageMonitoringQuery.data?.diffs ?? [];
  const trackedPageDiffs = trackedPageMonitoringQuery.data?.diffs ?? [];
  const articles = companyArticlesQuery.data ?? [];
  const { user } = useApp();

  if (isLoading) {
    return null;
  }

  const dateString = humanizedReportDateString(data);
  const totalEvents = pageDiffs.length + trackedPageDiffs.length + articles.length;
  const insights: Insight[] = data?.insights ?? [];
  const tldr = data?.tldr ?? "";
  const answers = data?.answers ?? [];

  return (
    <MainContentCard
      breadcrumbs={[
        { label: "Reports", href: "/reports" },
        { label: dateString, isPage: true },
      ]}
    >
      <div className="w-full flex justify-center pb-12">
        <div className="flex flex-col gap-10 items-center justify-center w-full">
          <div className="w-full flex justify-between mt-2 px-16">
            <div className="w-full font-semibold mb-2 text-xl">{dateString}</div>
            <ReportCompanyListButton reportId={reportId} />
          </div>

          {tldr && (
            <div className="w-full px-16">
              <div className="p-4 border-[#3B7EFF] shadow-sm shadow-[#3B7EFF]/40 border rounded-xl flex flex-col items-start gap-4  max-w-4xl">
                <div className="flex justify-between w-full">
                  <div className="font-semibold">Brief</div>
                  <ToolbarButton
                    onClick={() => {
                      navigator.clipboard.writeText(tldr).then(() => {
                        toast("✅ Brief copied to clipboard");
                      });
                    }}
                  >
                    <CopyIcon className="w-4 h-4" />
                    Copy
                  </ToolbarButton>
                </div>
                <div className="max-w-5xl">{tldr}</div>
                <div className="text-xs text-slate-400 mt-2">Brief is an experimental feature</div>
              </div>
            </div>
          )}

          <div className="w-full flex flex-col gap-6">
            <div className="w-full font-semibold flex items-center gap-2 px-16">Q & A</div>

            <div className="w-full flex gap-2 overflow-x-auto scroll-smooth">
              {answers.map((answer, index) => (
                <AnswerCell
                  key={index}
                  answer={answer}
                  index={index}
                  company={user.companies.find((c) => c.id == answer.company_id)}
                />
              ))}
            </div>
          </div>

          {insights.length > 0 && (
            <div className="w-full flex flex-col gap-6">
              <div className="w-full font-semibold flex items-center gap-2 px-16">Highlights</div>

              <div className="w-full flex gap-2 overflow-x-auto scroll-smooth">
                {insights.map((insight, index) => (
                  <HighlightCell
                    key={index}
                    insight={insight}
                    index={index}
                    company={user.companies.find((c) => c.name == insight.company_name)}
                  />
                ))}
              </div>
            </div>
          )}

          <div className="w-full my-4 h-[1px] bg-slate-200" />

          <div className="flex flex-col px-16 w-full gap-4 justify-start">
            <SectionHeader title="Activity" />

            <div className="flex gap-4 justify-start w-full">
              <EventStatsCell
                title="All"
                count={totalEvents}
                isActive={filter === "all"}
                onClick={() => setFilter("all")}
              />
              <EventStatsCell
                title="New & Deleted Pages"
                count={pageDiffs.length}
                isActive={filter === "website_monitoring"}
                onClick={() => setFilter("website_monitoring")}
              />
              <EventStatsCell
                title="Changed Pages"
                count={trackedPageDiffs.length}
                isActive={filter === "page_monitoring"}
                onClick={() => setFilter("page_monitoring")}
              />
              <EventStatsCell
                title="News"
                count={articles.length}
                isActive={filter === "news"}
                onClick={() => setFilter("news")}
              />
            </div>
          </div>

          {(filter == "all" || filter == "website_monitoring") && (
            <AdditionsDeletionsSection query={pageMonitoringQuery} />
          )}
          {(filter == "all" || filter == "page_monitoring") && (
            <PageChangesSection query={trackedPageMonitoringQuery} />
          )}
          {(filter == "all" || filter == "news") && <NewsSection query={companyArticlesQuery} />}
        </div>
      </div>
    </MainContentCard>
  );
};
