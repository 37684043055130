import React from "react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "~/shadcn/components/ui/dropdown-menu";
import { ShortcutButton } from "../ui/ShortcutButton";
import { SquareSplitHorizontal, StretchVertical, TriangleIcon, CheckIcon } from "lucide-react";
import { cn } from "~/utils/helpers";

export enum ViewMode {
  Slider = "Slider",
  Stack = "Stack",
  Absolute = "Pixel Diff",
}

export interface ViewModeConfig {
  name: ViewMode;
  icon: any;
}

export const VIEWMODES: ViewModeConfig[] = [
  { name: ViewMode.Slider, icon: SquareSplitHorizontal },
  { name: ViewMode.Stack, icon: StretchVertical },
  { name: ViewMode.Absolute, icon: TriangleIcon },
];

export const ViewModeSelector = ({ viewMode, setViewMode, showViewMode, setShowViewMode }) => {
  return (
    <DropdownMenu onOpenChange={setShowViewMode} open={showViewMode}>
      <DropdownMenuTrigger>
        <ShortcutButton
          listenKey="v"
          icon={<viewMode.icon className="w-4 h-4 text-orange-600" />}
          text="View mode"
          shortcut="v"
          onClick={() => {
            setShowViewMode(!showViewMode);
          }}
        />
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        {VIEWMODES.map((mode, index) => (
          <DropdownMenuItem
            key={index}
            onClick={() => {
              setViewMode(mode);
            }}
          >
            <div className="flex items-center w-full gap-2">
              <mode.icon size={12} />
              <span className="text-left">{mode.name}</span>
              <div className="flex-1" />
              <div className="text-xs text-gray-500 font-semibold">{index + 1}</div>
              <CheckIcon
                size={14}
                className={cn(viewMode.name === mode.name ? "text-blue-500" : "opacity-0")}
              />
            </div>
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
