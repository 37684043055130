import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { PlusIcon } from "lucide-react";
import { Dialog } from "~/shadcn/components/ui/dialog";
import {
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "~/shadcn/components/ui/dialog";
import { PrimaryButton } from "../ui/PrimaryButton";
import { Input } from "~/shadcn/components/ui/input";
import { useApp } from "~/providers/AppProvider";
import { toast } from "sonner";
import { queryClient } from "~/utils/client";
import { useMutation } from "@tanstack/react-query";
import { fetch_post } from "~/utils/helpers";

interface AddQuestionDialogProps {
  targetCompanyId?: number;
  closeDialog?: () => void;
}

export const AddQuestionDialog = ({ targetCompanyId, closeDialog }: AddQuestionDialogProps) => {
  const { user } = useApp();
  const [companyId, setCompanyId] = useState<number | null>(
    targetCompanyId || user.companies[0]?.id || null,
  );
  const targetCompany = user.companies.find((company) => company.id === companyId);
  const [question, setQuestion] = useState("");

  const mutation = useMutation({
    mutationFn: ({ companyId, question }: { companyId: number; question: string }) => {
      return fetch_post(`/companies/${companyId}/questions`, { question }).then(
        async (response) => {
          const json = await response.json();
          if (response.status == 400) {
            throw new Error(json.error);
          }
          return json;
        },
      );
    },
    onSuccess: () => {
      ["companies", companyId, "questions"].forEach((queryKey) => {
        queryClient.invalidateQueries({ queryKey: [queryKey] });
      });

      closeDialog && closeDialog();
    },
  });

  return (
    <DialogContent className="sm:max-w-[425px]">
      <DialogHeader>
        <DialogTitle>Ask a new question about {targetCompany?.name}</DialogTitle>
        <DialogDescription>
          Campana will try to answer the question every week using our research.
        </DialogDescription>
      </DialogHeader>

      <div className="flex flex-col gap-2 items-start w-full mt-4">
        <div className="text-xs font-semibold text-slate-700">Question</div>
        <Input
          id="question"
          placeholder="Did they launch a new product?"
          value={question}
          onChange={(e) => setQuestion(e.target.value)}
        />

        <div className="flex gap-1 items-center my-2">
          <div
            className={`w-2 h-2 rounded-full ${question.length < 5 ? "bg-red-500" : "bg-gray-300"}`}
          />
          <div
            className={`w-2 h-2 rounded-full ${
              question.length >= 5 && question.length < 15 ? "bg-yellow-500" : "bg-gray-300"
            }`}
          />
          <div
            className={`w-2 h-2 rounded-full ${
              question.length >= 15 ? "bg-green-500" : "bg-gray-300"
            }`}
          />
          <div className="text-xs text-slate-600 ml-2">
            {question.length < 5 ? "Too Short" : question.length < 15 ? "Could be Better" : "Good"}
          </div>
        </div>
      </div>

      <PrimaryButton
        onClick={() => {
          mutation.mutate(
            { companyId, question },
            {
              onSuccess: () => {
                setQuestion("");
                toast("✅ Question registered", {
                  description: "Campana will try to answer the question every week",
                });
              },
              onError: (error) => {
                toast(error.message, {
                  description: "Please fix the issue above",
                });
              },
            },
          );
        }}
        enabled={!mutation.isPending}
      >
        Add Question
      </PrimaryButton>
    </DialogContent>
  );
};

export const AddQuestionButton = ({ companyId }: { companyId: number }) => {
  const [showForm, setShowForm] = useState(false);
  const { state } = useLocation();
  const { id, showNewTrackedPageForm } = state || { id: null, showNewTrackedPageForm: false };

  useEffect(() => {
    setShowForm(showNewTrackedPageForm);
  }, [id, showNewTrackedPageForm]);

  return (
    <Dialog open={showForm} onOpenChange={setShowForm}>
      <PrimaryButton
        onClick={() => {
          setShowForm(true);
        }}
      >
        <PlusIcon className="w-4 h-4" />
        <span className="whitespace-nowrap">Add question</span>
      </PrimaryButton>
      <AddQuestionDialog targetCompanyId={companyId} closeDialog={() => setShowForm(false)} />
    </Dialog>
  );
};
