import React from 'react';
import { Newspaper, DollarSign, CalendarFold, BookText, Users, Megaphone, MessageSquareHeart } from 'lucide-react';

export enum EventType {
    Product = "Product",
    Funding = "Funding",
    Event = "Event",
    News = "News",
    Blog = "Blog",
    People = "People",
    Announcement = "Announcement",
    Reviews = "Reviews",
}

export const getEventTypeEnum = (eventTypeString: string): EventType | null => {
    const EventTypeStringToEnum = {
        "product": EventType.Product,
        "funding": EventType.Funding,
        "event": EventType.Event,
        "news": EventType.News,
        "blog": EventType.Blog,
        "people": EventType.People,
        "announcement": EventType.Announcement,
        "reviews": EventType.Reviews,
    }

    return EventTypeStringToEnum[eventTypeString.toLowerCase()];
}

export const getEventColorScheme = (type: EventType): [string, string] => {
    switch (type) {
        case EventType.Product:
            return ["#a31caf", "#fae8ff"]; // Primary (text / stroke), Secondary (bg)
        case EventType.Funding:
            return ["#15803c", "#dcfce7"];
        // TODO COLORS BELOW
        case EventType.Event:
            return ["#a16107", "#fef9c3"];
        case EventType.News:
            return ["#4438ca", "#e0e7ff"];
        case EventType.Blog:
            return ["#404040", "#f5f5f5"];
        case EventType.People:
            return ["#c2410c", "#ffedd5"];
        case EventType.Announcement:
            return ["#dc2626", "#fee2e2"];
        case EventType.Reviews:
            return ["#1d4fd8", "#dbeafe"];
        default:
            return ["#ffffff", "#000000"]; // Default colors if none match
    }
};

export const getEventTypeIcon = (type: EventType, size: number, color: string): JSX.Element => {
    switch (type) {
        case EventType.Product:
            return <Newspaper size={size} color={color} />
        case EventType.Funding:
            return <DollarSign size={size} color={color} />
        case EventType.Event:
            return <CalendarFold size={size} color={color} />
        case EventType.News:
            return <BookText size={size} color={color} />
        case EventType.Blog:
            return <BookText size={size} color={color} />
        case EventType.People:
            return <Users size={size} color={color} />
        case EventType.Announcement:
            return <Megaphone size={size} color={color} />
        case EventType.Reviews:
            return <MessageSquareHeart size={size} color={color} />
        default:
            return <Newspaper size={size} color={color} />
    }
};
