import React from "react";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "~/shadcn/components/ui/accordion";

const faqItems = [
  {
    value: "item-1",
    question: "How does Campana track changes in pages?",
    answer:
      "Using our in-house crawler and proxy system, Campana snapshots pages every 24 hours and performs a diff to find changes.",
  },
  {
    value: "item-2",
    question: "Can I only track changes in certain pages?",
    answer:
      'Yes. You can add URLs in our "Tracked Pages" UI to only track changes from a specific page.',
  },
  {
    value: "item-3",
    question: "Do you have a money-back guarantee?",
    answer:
      "Yes. If you are not happy with Campana, we will give you a refund. No questions asked.",
  },
  {
    value: "item-4",
    question: "How do you deal with noise?",
    answer:
      'Campana automatically categorizes events into predefined categories such as "Product", "Funding", "People", and so on. You can use these filters - along many more filters we provide - to remove noise from your timeline.',
  },
  {
    value: "item-5",
    question: "Have more questions?",
    answer: "Email us at team@studiolanes.com"
  },
];

export const FAQAccordion = () => {
  return (
    <Accordion type="single" collapsible className="w-full">
      {faqItems.map((item) => (
        <AccordionItem key={item.value} value={item.value}>
          <AccordionTrigger>
            <div className="text-left">{item.question}</div>
          </AccordionTrigger>
          <AccordionContent>{item.answer}</AccordionContent>
        </AccordionItem>
      ))}
    </Accordion>
  );
};
