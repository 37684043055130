import { CircleArrowRight } from "lucide-react";
import React from "react";

export const OnboardingNextButton = ({ type }: { type?: "button" | "submit"}) => {
  return (
    <button
      className="bg-gradient-to-b from-[#3B7EFF] to-[#3571E4] border-[#1458DB] border px-4 text-white text-sm font-semibold rounded-md w-full p-2 mt-8 shadow-lg hover:scale-[1.01] transition-transform flex justify-between items-center"
    >
      <span className="text-xs">Next</span>

      <CircleArrowRight size={16} />
    </button>
  );
};
