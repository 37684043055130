import React from "react";
import { Logo } from "./Logo";
import { sendSignOut } from "../../utils/methods";
import { ToolbarButton } from "./ToolbarButton";

export const OldHeader = () => {
  return (
    <div className="flex items-center justify-between h-[60px] py-4 px-6 bg-[#FAFAFA]">
      <Logo />
      <div>
        <ToolbarButton onClick={sendSignOut}>
          <span>Sign out</span>
        </ToolbarButton>
      </div>
    </div>
  );
};