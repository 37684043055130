import React, { useState } from "react";
import MainContentCard from "../ui/MainContentCard";
import { SectionHeader } from "../ui/SectionHeader";
import { MoreHorizontal } from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "~/shadcn/components/ui/dropdown-menu";
import {
  ColumnDef,
  ColumnFiltersState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Button } from "~/shadcn/components/ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "~/shadcn/components/ui/table";
import { TablePaginationControls } from "../ui/TablePaginationControls";
import { Report, humanizedReportDateString } from "~/models/Report";
import { EditColumnsButton } from "../ui/EditColumnsButton";
import { PageDiffStats } from "./PageDiffStats";
import { EmptyRow } from "../ui/EmptyRow";
import { useNavigate } from "react-router-dom";
import { fetch_get } from "~/utils/helpers";
import { useQuery } from "@tanstack/react-query";
import { Avatar, AvatarFallback, AvatarImage } from "~/shadcn/components/ui/avatar";
import { CompanyLogo } from "../ui/CompanyLogo";
import { useApp } from "~/providers/AppProvider";

export const columns: ColumnDef<Report>[] = [
  {
    accessorKey: "date",
    header: "Date",
    cell: ({ row }) => {
      return <div className="text-xs">{humanizedReportDateString(row.original)}</div>;
    },
    size: 100,
  },
  {
    accessorKey: "company_urls",
    header: "Companies",
    cell: ({ row }) => (
      <div className="text-xs font-semibold flex items-center">
        {row
          .getValue("company_urls")
          .slice(0, 3)
          .map((url, index) => (
            <div className="-ml-[6px] ">
              <CompanyLogo url={url} key={index} />
            </div>
          ))}

        {row.getValue("company_urls").length > 3 && (
          <div className="text-xs font-semibold ml-2">
            +{row.getValue("company_urls").length - 3} more
          </div>
        )}
      </div>
    ),
    size: 100,
  },
  {
    accessorKey: "New & Deleted Pages",
    header: () => <div className="text-xs text-center pl-10">New & Deleted Pages</div>,
    cell: ({ row }) => (
      <div className="flex items-center justify-center w-full pl-10">
        <PageDiffStats
          added={row.original["data"]?.["added_count"] || 0}
          removed={row.original["data"]?.["deleted_count"] || 0}
          unchanged={0}
        />
      </div>
    ),
    size: 80,
  },
  {
    accessorKey: "page changes",
    header: () => <div className="text-xs text-right">Page changes</div>,
    cell: ({ row }) => (
      <div className="text-sm text-right">
        {row.original["data"]?.tracked_page_diffs_count || 0}
      </div>
    ),
    size: 80,
  },
  {
    accessorKey: "news",
    header: () => <div className="text-xs text-right">News</div>,
    cell: ({ row }) => (
      <div className="text-sm text-right">{row.original["data"]?.articles_count || 0}</div>
    ),
    size: 80,
  },
  {
    id: "actions",
    cell: ({ row }) => {
      return (
        <div className="flex justify-end">
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" className="h-8 w-8 p-0">
                <span className="sr-only">Open menu</span>
                <MoreHorizontal className="h-4 w-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <DropdownMenuItem>Resend report</DropdownMenuItem>
              <DropdownMenuSeparator />
              <DropdownMenuItem className="text-destructive">Delete report</DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      );
    },
    size: 200,
  },
];

function ReportsTableBase({
  data,
  pageSize,
  didClick,
}: {
  data: Report[];
  pageSize: number;
  didClick: (row: Report) => void;
}) {
  const { user } = useApp();
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({});
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: pageSize,
  });

  const table = useReactTable({
    data,
    columns,
    onColumnFiltersChange: setColumnFilters,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    state: {
      columnFilters,
      columnVisibility,
      pagination,
    },
    initialState: {
      sorting: [{ id: "date", desc: true }],
    },
    defaultColumn: {
      minSize: 50,
      maxSize: 2000,
    },
  });

  return (
    <>
      <div className="flex gap-4 w-full">
        <EditColumnsButton table={table} />
      </div>

      <div className="flex flex-col gap-2 w-full">
        <Table
          {...{
            style: {
              width: "100%",
            },
          }}
        >
          <TableHeader className="text-xs">
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(header.column.columnDef.header, header.getContext())}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && "selected"}
                  className="cursor-pointer"
                  onClick={() => {
                    didClick(row.original);
                  }}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell
                      key={cell.id}
                      style={{
                        width: cell.column.getSize(),
                      }}
                    >
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <EmptyRow
                title="No reports"
                description="We are keeping track of your competitors and will send a report when we detect changes"
                columns={columns.length}
                numCompanies={user.companies.length}
              />
            )}
          </TableBody>
        </Table>
        <TablePaginationControls table={table} />
      </div>
    </>
  );
}

export const ReportsViewBase = ({
  data,
  didClickReport,
}: {
  data: Report[];
  didClickReport: (row: Report) => void;
}) => {
  return (
    <MainContentCard>
      <div className="w-full flex justify-center px-16 pb-12">
        <div className="flex flex-col gap-4 items-center justify-center w-full">
          <div className="mb-5 w-full">
            <SectionHeader title="Reports" />
          </div>

          <ReportsTableBase
            data={data}
            pageSize={10}
            didClick={(row) => {
              didClickReport(row);
            }}
          />
        </div>
      </div>
    </MainContentCard>
  );
};

export const ReportsView = () => {
  const navigate = useNavigate();
  const { isLoading, data } = useQuery({
    queryKey: ["reports"],
    queryFn: () => fetch_get("api/reports").then((response) => response.json()),
  });

  if (isLoading) {
    return null;
  }

  return (
    <ReportsViewBase
      data={data || []}
      didClickReport={(row) => {
        navigate(`/reports/${row.id}`);
      }}
    />
  );
};
