import { AvatarFallback } from "@radix-ui/react-avatar";
import React from "react";
import { Avatar, AvatarImage } from "~/shadcn/components/ui/avatar";
import { cn } from "~/utils/helpers";

export const CompanyLogo = ({
  url,
  className,
  enableFallback = true,
}: {
  url?: string;
  className?: string;
  enableFallback?: boolean;
}) => {
  return (
    <Avatar className="h-6 w-6 rounded border shadow-sm bg-white" key={url}>
      <AvatarImage src={`https://logo.clearbit.com/${url}`} alt={url} />
      {enableFallback && (
        <AvatarFallback className="bg-slate-50 text-xs justify-center h-full flex items-center w-full text-center">
          {url && new URL(url).hostname[0].toUpperCase()}
        </AvatarFallback>
      )}
    </Avatar>
  );
};
