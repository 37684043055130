import React, { useState } from "react";
import {
  ColumnFiltersState,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "~/shadcn/components/ui/select";
import { useQuery } from "@tanstack/react-query";
import { Input } from "~/shadcn/components/ui/input";
import { DialogContent, DialogHeader, DialogTitle } from "~/shadcn/components/ui/dialog";
import { fetch_get } from "~/utils/helpers";
import { URLManagementTableColumns } from "../trackedPages/ModalURLManagementColumns";
import { TrackedPageEntry } from "~/models/TrackedPage";
import { useApp } from "~/providers/AppProvider";
import { Button } from "~/shadcn/components/ui/button";
import { URLManagementTableBase } from "../trackedPages/TrackedPagesURLTable";

export function URLManagementDialogBase({
  data,
  companyId,
}: {
  data: TrackedPageEntry[];
  companyId?: number;
}) {
  const [rowSelection, setRowSelection] = useState({});
  const initialColumnFilters = companyId ? [{ id: "company_id", value: companyId }] : [];
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>(initialColumnFilters);
  const [columnVisibility, setColumnVisibility] = useState({
    url: true,
    company_id: false,
  });

  const table = useReactTable({
    data,
    columns: URLManagementTableColumns,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onRowSelectionChange: setRowSelection,
    getRowId: (row) => row.id.toString(),
    state: {
      columnFilters,
      rowSelection,
      columnVisibility,
    },
    initialState: {
      columnFilters: [{ id: "company_id", value: companyId }],
    },
  });

  const { user } = useApp();

  return (
    <DialogContent>
      <DialogHeader>
        <DialogTitle>Manage Tracked Pages</DialogTitle>
      </DialogHeader>
      <div className="w-full h-full">
        <div className="flex items-center py-4 gap-2">
          <Input
            placeholder="Find URL..."
            value={(table.getColumn("url")?.getFilterValue() as string) ?? ""}
            onChange={(event) => table.getColumn("url")?.setFilterValue(event.target.value)}
          />
          {!companyId && (
            <Select
              defaultValue="all"
              onValueChange={(value) => {
                if (value === "all") {
                  setColumnFilters([]);
                } else {
                  setColumnFilters([{ id: "company_id", value: value }]);
                }
              }}
            >
              <SelectTrigger className="w-[180px]">
                <SelectValue placeholder="All companies" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="all">All companies</SelectItem>
                {user?.companies.map((company) => (
                  <SelectItem key={company.id} value={company.id}>
                    {company.name}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          )}
        </div>
        <div className="rounded-md border max-h-[450px] overflow-y-auto">
          <URLManagementTableBase table={table} columnLength={URLManagementTableColumns.length} />
        </div>
      </div>
      <div className="flex items-center justify-end space-x-2 py-4">
        <Button
          variant="outline"
          size="sm"
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
          Previous
        </Button>
        <Button
          variant="outline"
          size="sm"
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        >
          Next
        </Button>
      </div>
    </DialogContent>
  );
}

export function URLManagementDialog({ companyId }: { companyId?: number }) {
  const { isLoading, data } = useQuery({
    queryKey: ["tracked_pages"],
    queryFn: () => fetch_get("/tracked_pages").then((response) => response.json()),
  });

  if (isLoading) {
    return null;
  }

  return <URLManagementDialogBase data={data || []} companyId={companyId} />;
}
