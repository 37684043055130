import React from "react";
import { ToolbarButton } from "./ToolbarButton";

interface ShortcutButtonProps {
    listenKey: string;
    onClick: () => void;
    icon: React.ReactNode;
    text: string;
    shortcut: string;
    enabled?: boolean;
}

export function ShortcutButton({ listenKey, onClick, icon, text, shortcut, enabled = true }: ShortcutButtonProps) {
    return (
        <ToolbarButton listenKey={listenKey} onClick={onClick} disabled={!enabled}>
            {icon}
            <div className="text-xs font-semibold whitespace-nowrap">{text}</div>
            <div className="text-xs font-medium text-slate-500">{shortcut}</div>
        </ToolbarButton>
    )
}
