import React from "react";
import { Navigate, Outlet, useParams } from "react-router-dom";
import { ContentHeader } from "./sidetab/Sidetab";
import { useApp } from "~/providers/AppProvider";
import { SidebarProvider } from "~/providers/SidebarProvider";
import { Toaster } from "~/shadcn/components/ui/sonner";

export const RootView = () => {
  const { user } = useApp();
  const { companyId } = useParams();

  if (user.onboarded && user.subscribed) {
    return (
      <SidebarProvider>
        <Toaster />
        <div className="flex h-screen bg-white">
          <div className="flex">
            <ContentHeader />
          </div>

          <div className="flex flex-1 w-2/5">
            <Outlet context={{ companyData: [], companyId }} />
          </div>
        </div>
      </SidebarProvider>
    );
  } else {
    return <Navigate to="/onboarding" replace={true} />;
  }
};
