import React from "react";
import { formatDistanceToNow, parseISO } from "date-fns";
import { EventPill } from "../ui/EventPill";
import { getEventTypeEnum } from "../../types/EventType";
export interface CompanyArticle {
  title: string;
  date: string;
  source: string;
  imageUrl: string;
  id?: number;
  url?: string;
  snippet?: string; // Do we ever use this?
  eventType?: string;
  companyName?: string;
}

export const NewsRow = ({
  imageUrl,
  source,
  date,
  title,
  eventType,
  companyName,
}: CompanyArticle) => {
  const dateObject = parseISO(date);
  const relativeDate = formatDistanceToNow(dateObject);

  return (
    <div className="flex items-center gap-10 w-full justify-between py-4">
      <div>
        <div className="flex flex-col gap-4 items-start">
          <div className="flex gap-2 text-xs">
            <span className="font-medium">{source}</span>
            <span>·</span>
            <span className="">{companyName}</span>
            <span className="text-slate-700 ml-4">{relativeDate}</span>
          </div>

          <p className="text-sm text-left">{title}</p>

          {eventType && <EventPill type={getEventTypeEnum(eventType)} />}
        </div>
      </div>

      <img
        src={imageUrl}
        alt={title}
        className="w-32 h-20 object-cover rounded-lg shadow-sm"
      />
    </div>
  );
};
