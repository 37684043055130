import React, { useState } from "react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "~/shadcn/components/ui/dropdown-menu";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "~/shadcn/components/ui/alert-dialog";
import { buttonVariants } from "~/shadcn/components/ui/button";
import { queryClient } from "~/utils/client";
import { cn, fetch_delete } from "~/utils/helpers";
import { toast } from "sonner";
import { useMutation } from "@tanstack/react-query";
import { MoreHorizontal } from "lucide-react";
import { ExternalLinkIcon, TrashIcon } from "@radix-ui/react-icons";
import { useApp } from "~/providers/AppProvider";

export default function CompanyRowDropdown({
  companyId,
  companyName,
  className,
  didDeleteCompany,
}: {
  companyId: number;
  companyName: string;
  className?: string;
  didDeleteCompany?: () => void;
}) {
  const { user } = useApp();
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const mutation = useMutation({
    mutationFn: async () => {
      return fetch_delete(`/companies/${companyId}`, {}).then(async (response) => {
        var json = await response.json();
        if (response.status == 400) {
          throw new Error(json.error);
        }

        return json;
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["me"] });
      toast(`🗑️ Successfully deleted ${companyName}`, {
        description: `${companyName} will no longer be tracked`,
      });
      didDeleteCompany?.();
    },
    onError: (error) => {
      toast(error.message, { description: "We have been notified and will resolve it shortly" });
    },
  });

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger>
          <div className={cn("h-8 w-8 p-0 items-center justify-center flex", className)}>
            <span className="sr-only">Open menu</span>
            <MoreHorizontal className="h-4 w-4" />
          </div>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          <DropdownMenuItem
            onClick={() => {
              const company = user.companies.find((c) => c.id === companyId);
              window.open(company.url, "_blank");
            }}
          >
            <ExternalLinkIcon className="w-4 h-4 mr-2" />
            Open Page
          </DropdownMenuItem>
          <DropdownMenuSeparator />
          <DropdownMenuItem
            onClick={() => {
              setShowDeleteDialog(true);
            }}
          >
            <TrashIcon className="w-4 h-4 mr-2 text-red-500" />
            <span className="text-red-500">Delete Company</span>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>

      <AlertDialog open={showDeleteDialog} onOpenChange={setShowDeleteDialog}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Delete company?</AlertDialogTitle>
            <AlertDialogDescription>
              This action cannot be undone. This will permanently delete all collected information
              about the company and stop tracking it.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction
              className={buttonVariants({ variant: "destructive" })}
              onClick={() => {
                mutation.mutate();
              }}
            >
              Delete
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
}
