import { Settings } from "lucide-react";
import React from "react";
import { useSidebar } from "../../providers/SidebarProvider";
import { cn } from "~/utils/helpers";
import { FeedbackButton } from "./FeedbackButton";
import { useApp } from "~/providers/AppProvider";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "~/shadcn/components/ui/breadcrumb";
import { HelpDropdownButton } from "../navBar/HelpDropdownButton";
import { AddNewButton } from "../navBar/AddNewButton";

interface Breadcrumb {
  href?: string;
  label: string;
  isPage?: boolean;
}

interface MainContentCardProps {
  children: React.ReactNode;
  enableHorizontalPadding?: boolean;
  disableOverflow?: boolean;
  breadcrumbs?: Breadcrumb[];
}

const NavBarButton = ({
  children,
  onClick,
}: {
  children: React.ReactNode;
  onClick: () => void;
}) => {
  return (
    <button className="p-2 text-slate-700 hover:bg-slate-100 rounded-md" onClick={onClick}>
      {children}
    </button>
  );
};

const NavigationBreadcrumbView = ({ breadcrumbs }: { breadcrumbs: Breadcrumb[] }) => {
  return (
    <Breadcrumb>
      <BreadcrumbList>
        {breadcrumbs.map((breadcrumb, index) => (
          <React.Fragment key={index}>
            <BreadcrumbItem>
              {breadcrumb.isPage ? (
                <BreadcrumbPage>{breadcrumb.label}</BreadcrumbPage>
              ) : (
                <BreadcrumbLink href={breadcrumb.href}>{breadcrumb.label}</BreadcrumbLink>
              )}
            </BreadcrumbItem>
            {index < breadcrumbs.length - 1 && <BreadcrumbSeparator />}
          </React.Fragment>
        ))}
      </BreadcrumbList>
    </Breadcrumb>
  );
};

const MainContentCard = ({
  children,
  breadcrumbs = null,
  enableHorizontalPadding = false,
  disableOverflow = false,
}: MainContentCardProps) => {
  const { user } = useApp();
  const { selectAndNavigateToTab } = useSidebar();

  return (
    <div
      className={cn(
        `relative flex flex-col my-4 ml-2 mr-4 w-full`,
        disableOverflow ? "overflow-y-clip" : "overflow-y-auto",
      )}
    >
      <div className="w-full bg-white flex items-center justify-between mb-2 sticky left-0 top-0 gap-3 px-4 py-2 z-50">
        <div className="flex items-center gap-3">
          {breadcrumbs && <NavigationBreadcrumbView breadcrumbs={breadcrumbs} />}
        </div>

        <div className="w-max flex items-center gap-4">
          <FeedbackButton user={user} />
          <div className="mx-2 w-[1px] h-[16px] bg-slate-200" />

          <HelpDropdownButton />

          <NavBarButton
            onClick={() => {
              selectAndNavigateToTab("settings");
            }}
          >
            <Settings
              className={cn("w-5 h-5", {
                "text-blue-500": window.location.pathname === "/settings",
              })}
            />
          </NavBarButton>
          <AddNewButton />
        </div>
      </div>

      <div className={`flex flex-col gap-4 mb-8 h-full ${enableHorizontalPadding ? "mx-16" : ""}`}>
        {children}
      </div>
    </div>
  );
};

export default MainContentCard;
