import React from "react";
import ViewContainer from "../ui/ViewContainer";
import { useQuery } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import { cn, fetch_get } from "~/utils/helpers";
import { Building, Loader2Icon, OctagonXIcon } from "lucide-react";
import { Divider } from "../ui/Divider";
import { HoverCard, HoverCardContent, HoverCardTrigger } from "~/shadcn/components/ui/hover-card";
import { AddTrackedPagesButton } from "../trackedPages/AddTrackedPagesButton";
import { ChevronLeftIcon, QuestionMarkCircledIcon } from "@radix-ui/react-icons";
import { URLManagementTable } from "../trackedPages/TrackedPagesURLTable";
import { CompanyDetailURLManagementColumns } from "./CompanyDetailURLManagementColumns";
import { Switch } from "~/shadcn/components/ui/switch";
import { useUpdateUserSettings, useUserSettings } from "~/queries/user-settings";
import CompanyRowDropdown from "./CompanyRowDropdown";
import { Avatar, AvatarImage } from "~/shadcn/components/ui/avatar";
import { AddQuestionButton } from "./AddQuestionButton";
import { QuestionsTable } from "./QuestionsTable";

function Header({ data, companyId }: { data: any; companyId: number }) {
  const navigate = useNavigate();

  return (
    <div className="flex items-center w-full">
      <div className="flex flex-col justify-start gap-4 w-full">
        <button
          className="flex items-center gap-2 text-blue-600 mb-6"
          onClick={() => {
            navigate("/following");
          }}
        >
          <ChevronLeftIcon className="w-4 h-4" />
          <div className="text-xs">Companies</div>
        </button>

        <div className="flex items-center gap-2">
          <Building className="w-4 h-4 text-slate-400" />
          <div className="text-xs font-medium text-slate-400">COMPANY</div>
        </div>

        <div className="flex items-center gap-3">
          <Avatar className="w-8 h-8 rounded-lg border shadow">
            <AvatarImage src={"https://logo.clearbit.com/" + data?.url} />
          </Avatar>
          <div className={cn("text-2xl font-medium", data?.name == null && "text-transparent")}>
            {data?.name || "..."}
          </div>
        </div>
      </div>

      <CompanyRowDropdown
        companyName={data?.name}
        companyId={companyId}
        className="border hover:bg-slate-50 rounded"
        didDeleteCompany={() => {
          navigate("/following");
        }}
      />
    </div>
  );
}

function SectionHeader({
  title,
  children,
  tip,
}: {
  title: string;
  children?: React.ReactNode;
  tip?: string;
}) {
  return (
    <HoverCard>
      <div className="flex flex-col gap-4 w-full">
        <div className="flex justify-between w-full items-center">
          <div className="flex items-center gap-2">
            <div className="font-medium">{title}</div>
            {tip && (
              <>
                <HoverCardTrigger asChild>
                  <QuestionMarkCircledIcon className="w-4 h-4 text-slate-400 cursor-help" />
                </HoverCardTrigger>
                <HoverCardContent className="text-sm">
                  <div>{tip}</div>
                </HoverCardContent>
              </>
            )}
          </div>
          {children}
        </div>
        <Divider />
      </div>
    </HoverCard>
  );
}

function SitemapChangesNotAvailableBanner({ shortenedURL }: { shortenedURL: string }) {
  return (
    <HoverCard>
      <HoverCardTrigger asChild>
        <div className="flex gap-2 bg-slate-100 rounded-lg p-4 items-center justify-center cursor-help">
          <OctagonXIcon className="w-4 h-4 text-slate-600" />
          <span className="text-center text-sm text-slate-600">
            Feature not available for {shortenedURL}
          </span>
        </div>
      </HoverCardTrigger>
      <HoverCardContent className="text-sm">
        <div className="flex flex-col gap-2 items-start">
          <span className="font-semibold text-xs text-slate-600">
            Why is this feature not available?
          </span>
          <div>
            {shortenedURL} does not have a publicly accessible sitemap or disallows frequent
            scraping of their website.
          </div>
        </div>
      </HoverCardContent>
    </HoverCard>
  );
}

function DescriptionSection({ overview }: { overview?: string }) {
  return (
    <div className="flex flex-col gap-2">
      <SectionHeader title="Description" />
      <div className="text-sm text-slate-6000">{overview || "No description"}</div>
    </div>
  );
}

export default function CompanyDetailView() {
  const { companyId } = useParams();
  const { isLoading: isCompanyLoading, data: company } = useQuery({
    queryKey: ["company", companyId],
    queryFn: () => {
      return fetch_get(`/companies/${companyId}`).then((response) => response.json());
    },
  });
  const shortenedURL = company?.url?.replace(/^https?:\/\//, "");

  const { isLoading, data } = useUserSettings();
  const mutation = useUpdateUserSettings();

  return (
    <ViewContainer>
      <div className="flex flex-col gap-12 w-full my-16">
        <Header data={company} companyId={Number(companyId)} />

        <DescriptionSection overview={company?.overview} />

        <div className="flex flex-col w-full gap-4">
          <SectionHeader title="New & Removed Pages" />
          {company?.data?.invalid_sitemap === true ? (
            <SitemapChangesNotAvailableBanner shortenedURL={shortenedURL} />
          ) : (
            <div className="flex justify-between gap-2 w-full items-center">
              <div className="flex flex-col gap-1">
                <div className="text-sm">Automatically detect new and removed pages</div>
                <div className="text-xs text-slate-600">
                  This setting is global and will impact all companies you follow.
                </div>
              </div>

              {isLoading ? (
                <Loader2Icon className="w-4 h-4 animate-spin" />
              ) : (
                <Switch
                  checked={data?.detect_additions_deletions}
                  onCheckedChange={(value) => {
                    mutation.mutate({ detect_additions_deletions: value });
                  }}
                />
              )}
            </div>
          )}
        </div>

        <div className="flex flex-col w-full">
          <div className="flex justify-between">
            <SectionHeader
              title="Questions"
              tip="Every week, Campana will try to answer questions here based on our research."
            >
              <AddQuestionButton companyId={Number(companyId)} />
            </SectionHeader>
          </div>
          <QuestionsTable companyId={companyId} />
        </div>

        <div className="flex flex-col w-full">
          <div className="flex justify-between">
            <SectionHeader
              title="Tracked Pages"
              tip="Add specific pages of a company to track for changes."
            >
              <AddTrackedPagesButton companyId={Number(companyId)} />
            </SectionHeader>
          </div>

          <URLManagementTable columns={CompanyDetailURLManagementColumns} companyId={companyId} />
        </div>
      </div>
    </ViewContainer>
  );
}
