import React, { useEffect, useState } from "react";
import { ExternalLinkIcon } from "@radix-ui/react-icons";
import { formatDistanceToNow } from "date-fns";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { PulseColor, PulsingDot } from "../ui/PulsingDot";
import { Separator } from "~/shadcn/components/ui/separator";
import { ImgComparisonSlider } from "@img-comparison-slider/react";
import { ViewMode, ViewModeConfig, VIEWMODES, ViewModeSelector } from "./ViewModeSelector";
import PageDiffHistoryList from "./PageDiffHistoryList";
import { useQuery } from "@tanstack/react-query";
import { fetch_get } from "~/utils/helpers";

const DetailSection = ({ title, children }: { title: string; children: React.ReactNode }) => {
  return (
    <div className="flex flex-col items-start gap-2 text-sm">
      <div className="text-slate-600">{title}</div>
      <div className="flex items-center gap-2">{children}</div>
    </div>
  );
};

const PageMetaDetailSection = ({
  companyId,
  companyName,
  latestDiff,
}: {
  companyId: string;
  companyName: string;
  latestDiff?: any;
}) => {
  return (
    <div className="flex gap-4 mx-10">
      <DetailSection title="Status">
        <PulsingDot color={PulseColor.GREEN} />
        <div>Tracking</div>
      </DetailSection>

      <Separator orientation="vertical" />

      <DetailSection title="Last Updated">
        <div>
          {latestDiff !== null ? (
            formatDistanceToNow(new Date(latestDiff.date), {
              addSuffix: true,
            })
          ) : (
            <div>Never</div>
          )}
        </div>
      </DetailSection>

      <Separator orientation="vertical" />

      <DetailSection title="Company">
        {companyId ? (
          <Link to={`/following/${companyId}`} className="text-blue-500">
            {companyName}
          </Link>
        ) : (
          <div>No company</div>
        )}
      </DetailSection>
    </div>
  );
};

export const PageDetailView = () => {
  const { trackedPageId } = useParams();
  const [searchParams] = useSearchParams();
  const pageChangeId = searchParams.get("pageChangeId");

  const [viewMode, setViewMode] = useState<ViewModeConfig>(VIEWMODES[0]);
  const [showViewMode, setShowViewMode] = useState(false);
  const [selectedDiff, setSelectedDiff] = useState(null);
  const [latestDiff, setLatestDiff] = useState(null);

  const { isLoading, data } = useQuery({
    queryKey: ["tracked_pages", trackedPageId],
    queryFn: () => fetch_get(`/tracked_pages/${trackedPageId}`).then((res) => res.json()),
  });

  useEffect(() => {
    if (showViewMode) {
      const handleKeyPress = (event: KeyboardEvent) => {
        if (event.key === "1") {
          setViewMode(VIEWMODES[0]);
        } else if (event.key === "2") {
          setViewMode(VIEWMODES[1]);
        } else if (event.key === "3") {
          setViewMode(VIEWMODES[2]);
        }
      };

      window.addEventListener("keydown", handleKeyPress);
      return () => {
        window.removeEventListener("keydown", handleKeyPress);
      };
    }
  }, [showViewMode]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const trackedPage = data;
  const url = trackedPage?.url;
  const formattedUrl = url?.replace(/^https?:\/\//, "")?.replace(/^www\./, "");

  return (
    <div className="flex w-full">
      <div className="flex flex-col mt-8 w-1/3">
        <div className="flex items-center gap-4 mb-8 mx-10">
          <div className="text-xl font-medium">{formattedUrl}</div>
          <a href={url} target="_blank">
            <ExternalLinkIcon className="h-4 w-4 text-slate-600" />
          </a>
        </div>

        <PageMetaDetailSection
          companyId={trackedPage?.company_id}
          companyName={trackedPage?.company_name}
          latestDiff={latestDiff}
        />

        <Separator className="mt-8" />

        <PageDiffHistoryList
          trackedPageId={trackedPageId}
          pageChangeId={pageChangeId}
          selectedDiff={selectedDiff}
          setSelectedDiff={setSelectedDiff}
          latestDiff={latestDiff}
          setLatestDiff={setLatestDiff}
        />
      </div>

      <div className="w-[1px] h-full bg-slate-200" />

      <div className="flex flex-col w-2/3 px-8 py-5 gap-8">
        <div className="flex items-end justify-between">
          {selectedDiff && (
            <div className="flex flex-col gap-2">
              <div className="text-lg font-medium">{selectedDiff.data.summary ?? "No changes"}</div>
              <div className="text-slate-6000 text-sm">
                {new Date(selectedDiff.date).toLocaleDateString("en-US")}
              </div>
            </div>
          )}
          <ViewModeSelector
            viewMode={viewMode}
            setViewMode={setViewMode}
            showViewMode={showViewMode}
            setShowViewMode={setShowViewMode}
          />
        </div>

        <div className="flex flex-col border rounded-md relative overflow-y-scroll h-full">
          <div className="text-slate-6000 text-xs p-2 text-center w-full sticky top-0 bg-slate-100 z-[10]">
            {selectedDiff?.url}
          </div>
          {selectedDiff && (
            <>
              {viewMode.name === ViewMode.Absolute && (
                <img slot="first" src={selectedDiff.data.diff_url} />
              )}

              {viewMode.name === ViewMode.Slider && (
                <div className="h-fit">
                  <ImgComparisonSlider
                    style={{ "--divider-color": "black" }}
                    className="focus:outline-none"
                  >
                    <img slot="first" src={selectedDiff.data.old_url} />
                    <img slot="second" src={selectedDiff.data.new_url} />
                  </ImgComparisonSlider>
                </div>
              )}

              {viewMode.name === ViewMode.Stack && (
                <div className="flex gap-2 w-fit">
                  <img src={selectedDiff.data.old_url} style={{ width: "50%", height: "auto" }} />
                  <img src={selectedDiff.data.new_url} style={{ width: "50%", height: "auto" }} />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};
