import React, { useState } from "react";
import { Popover, PopoverContent, PopoverTrigger } from "~/shadcn/components/ui/popover";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "~/shadcn/components/ui/select";
import { PlusCircleIcon, XCircleIcon } from "lucide-react";
import { capitalize } from "~/utils/helpers";

interface FilterPillProps {
  title: string;
  source: string[];
  didApply: (value: string) => void;
  filter: string | null;
  selectedValue: string | null;
  setSelectedValue: (value: string) => void;
}

function FilterPillContent({
  title,
  source,
  didApply,
  filter,
  selectedValue,
  setSelectedValue,
}: FilterPillProps) {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  return (
    <PopoverContent>
      <div className="flex flex-col gap-4">
        <div className="text-sm font-semibold">Filter by {title}</div>
        <Select onValueChange={setSelectedValue}>
          <SelectTrigger className="w-full">
            <SelectValue placeholder={filter ? filter : "All"} />
          </SelectTrigger>
          <SelectContent>
            {source.map((type) => {
              return (
                <SelectItem key={type} value={type}>
                  {capitalize(type)}
                </SelectItem>
              );
            })}
          </SelectContent>
        </Select>

        <button
          onClick={() => {
            setIsDialogOpen(false);
            if (selectedValue) {
              didApply(selectedValue);
            }
          }}
          className="w-full py-2 rounded-lg bg-blue-500 border border-blue-600 text-white text-sm font-medium shadow-sm"
        >
          Apply
        </button>
      </div>
    </PopoverContent>
  );
}

interface FilterPillButtonProps {
  title: string;
  source: string[];
  filter: string | null;
  setFilter: (filter: string) => void;
}

export function FilterPillButton({ title, source, filter, setFilter }: FilterPillButtonProps) {
  const [selectedValue, setSelectedValue] = useState<string | null>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  return (
    <Popover open={isDialogOpen} onOpenChange={setIsDialogOpen}>
      <div className="flex items-center gap-2">
        {filter ? (
          <div
            className={`flex items-center gap-2 border border-slate-200 px-2 py-1 rounded-full ${
              filter ? "border" : "border-dashed "
            }`}
          >
            <button
              onClick={() => {
                setFilter(null);
                setSelectedValue(null);
              }}
            >
              <XCircleIcon className="w-4 h-4 text-slate-500" />
            </button>
            <div className="text-xs text-slate-500">{title}</div>
            <div className="w-px h-4 bg-slate-200" />

            <PopoverTrigger className="w-fit">
              <div className="text-xs text-blue-500 font-medium">{capitalize(filter)}</div>
              <FilterPillContent
                title={title}
                source={source}
                didApply={setFilter}
                filter={filter}
                selectedValue={selectedValue}
                setSelectedValue={setSelectedValue}
              />
            </PopoverTrigger>
          </div>
        ) : (
          <PopoverTrigger className="w-fit">
            <div
              className={`flex items-center gap-2 border border-slate-200 px-2 py-1 rounded-full border-dashed`}
            >
              <PlusCircleIcon className="w-4 h-4 text-slate-500" />
              <div className="text-xs text-slate-500">{title}</div>
            </div>
            <FilterPillContent
              title={title}
              source={source}
              didApply={setFilter}
              filter={filter}
              selectedValue={selectedValue}
              setSelectedValue={setSelectedValue}
            />
          </PopoverTrigger>
        )}
      </div>
    </Popover>
  );
}
