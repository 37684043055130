export interface Report {
  id: string;
  frequency: string;
  date: string;
  company_name: string;
  news: number;
  page_stats: { added: number; removed: number; unchanged: number };
}

export function humanizedReportDateString(report: Report): string {
  let formattedDate: string;
  const date = new Date(report.date);

  if (report.frequency === "weekly") {
    const startDate = new Date(date);
    startDate.setDate(date.getDate() - 7);
    const weekNumber = Math.ceil(startDate.getDate() / 7);
    formattedDate = `Week ${weekNumber}, ${startDate.toLocaleString("default", {
      month: "long",
    })}`;
  } else if (report.frequency === "daily") {
    formattedDate = date.toLocaleDateString("default", { month: "short", day: "numeric" });
  }

  return formattedDate;
}
