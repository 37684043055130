import React, { useEffect, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { toast } from "sonner";
import { queryClient } from "~/utils/client";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "~/shadcn/components/ui/dialog";
import { Input } from "~/shadcn/components/ui/input";
import { PrimaryButton } from "../ui/PrimaryButton";
import { fetch_post } from "~/utils/helpers";
import { AddTrackedURLQuestionsDescription } from "./AddTrackedURLQuestions";
import { ProgressIndicator } from "../ui/ProgressIndicator";
import { Loader2, PlusIcon } from "lucide-react";
import { URLManagementTable } from "../trackedPages/TrackedPagesURLTable";
import { URLManagementTableColumns } from "../trackedPages/ModalURLManagementColumns";
import useRecommendedCompanyTrackedPages from "~/queries/recommended-tracked-pages";
import { Separator } from "~/shadcn/components/ui/separator";
import useAddTrackedPages from "~/queries/use-add-tracked-page";

export const AddCompanyButton = ({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean;
  setIsOpen: (show: boolean) => void;
}) => {
  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <PrimaryButton onClick={() => setIsOpen(true)}>
        <PlusIcon className="w-4 h-4" />
        <span className="whitespace-nowrap">Track new</span>
      </PrimaryButton>

      <NewCompanyDialog showURLForm={isOpen} setShowURLForm={setIsOpen} />
    </Dialog>
  );
};

const EnterCompanyURLView = ({
  setUrl,
  submit,
  pending,
}: {
  setUrl: (url: string) => void;
  submit: () => void;
  pending: boolean;
}) => {
  return (
    <>
      <DialogHeader>
        <DialogTitle>Track a new company</DialogTitle>
        <DialogDescription>Be notified of new and deleted pages</DialogDescription>
      </DialogHeader>
      <form
        className="flex flex-col gap-4 items-start w-full my-4"
        onSubmit={(e) => {
          e.preventDefault();
          submit();
        }}
      >
        <Input
          id="url"
          placeholder="https://www.competitor.com"
          onChange={(e) => setUrl(e.target.value)}
        />
      </form>
      <PrimaryButton
        onClick={() => {
          submit();
        }}
        enabled={!pending}
      >
        Track
      </PrimaryButton>
    </>
  );
};

export const AddCompanyURLsView = ({
  companyId,
  didPressDone,
}: {
  companyId: string;
  didPressDone: () => void;
}) => {
  const { isLoading, data } = useRecommendedCompanyTrackedPages(companyId);
  const [url, setUrl] = useState("");
  const [activeURL, setActiveURL] = useState("");
  const [addedURLs, setAddedURLs] = useState(new Set());
  const recs = (data ? data["recs"] : []).filter((rec) => !addedURLs.has(rec.url));

  const mutation = useAddTrackedPages(
    () => {
      toast("✅ Tracking started for " + activeURL.replace(/^https?:\/\/(www\.)?/, ""));
      setAddedURLs((prev) => new Set(prev).add(activeURL));
      setActiveURL("");
      setUrl("");
    },
    (err) => {
      setActiveURL("");
      setUrl("");
      toast(err ? err : "💣 Something went wrong", {
        description: "We have been notified and are fixing the issue",
      });
    },
  );

  function addURL(url: string) {
    setActiveURL(url);
    mutation.mutate({ companyId, url });
  }

  return (
    <>
      <DialogHeader>
        <DialogTitle>Track page changes</DialogTitle>
        <DialogDescription>
          <AddTrackedURLQuestionsDescription />
        </DialogDescription>
      </DialogHeader>
      <form
        className="mt-4"
        onSubmit={(e) => {
          e.preventDefault();
          addURL(url);
        }}
      >
        <div className="flex gap-2 items-start w-full">
          <Input
            id="url"
            placeholder="https://www.company.com/page"
            onChange={(e) => setUrl(e.target.value)}
            value={url}
          />
          <PrimaryButton onClick={() => {}}>Track</PrimaryButton>
        </div>
      </form>

      <div className="flex flex-col gap-4 overflow-y-auto">
        <div className="mt-2 text-xs font-semibold text-slate-700">Recommended</div>
        {recs?.length > 0 ? (
          <div className="flex flex-col w-full gap-2 pl-1">
            {(recs || []).map((rec) => {
              return (
                <div className="flex justify-between items-center gap-1" key={rec.url}>
                  <a href="https://openai.com/models" target="_blank" className="text-sm">
                    {rec.url.replace(/^https?:\/\/(www\.)?/, "")}
                  </a>
                  <button
                    className="rounded-full flex items-center pl-1 pr-2 text-blue-700 bg-slate-100 hover:bg-blue-100 transition-all"
                    onClick={() => {
                      addURL(rec.url);
                    }}
                  >
                    {activeURL == rec.url ? (
                      <Loader2 className="w-6 h-6 p-1 animate-spin" />
                    ) : (
                      <>
                        <PlusIcon className="w-6 h-6 p-1" />
                        <span className="text-xs font-medium">Add</span>
                      </>
                    )}
                  </button>
                </div>
              );
            })}
          </div>
        ) : (
          <div className="text-xs text-slate-700 p-4 bg-slate-50 rounded-lg text-center">
            {isLoading ? (
              <div className="text-xs text-slate-700 p-2 bg-slate-50 rounded-lg text-center">
                Loading recommendations...
              </div>
            ) : (
              <div className="text-xs text-slate-700 p-2 bg-slate-50 rounded-lg text-center">
                No recommendations
              </div>
            )}
          </div>
        )}

        <Separator />

        <div className="mt-2 text-xs font-semibold text-slate-700">Tracked Pages</div>
        <URLManagementTable columns={URLManagementTableColumns} companyId={companyId} />
      </div>
      <PrimaryButton
        onClick={() => {
          didPressDone();
        }}
      >
        Done
      </PrimaryButton>
    </>
  );
};

export const NewCompanyDialog = ({
  showURLForm,
  setShowURLForm,
}: {
  showURLForm: boolean;
  setShowURLForm: (show: boolean) => void;
}) => {
  const mutation = useMutation({
    mutationFn: ({ url }: { url: string }) => {
      return fetch_post("/companies", { url }).then(async (response) => {
        var json = await response.json();
        if (response.status == 400) {
          throw new Error(json.error);
        }

        return json;
      });
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ["me"] });
      if (data.status === "new") {
        const companyId = data.company.id;
        setCompanyId(companyId);
        setStep(2);
      } else if (data.status === "existing") {
        toast("❗ Company is already being tracked");
        setShowURLForm(false);
      }
    },
    onError: (error) => {
      toast(error.message, {
        description: "Please email team@studiolanes.com if you would like to track more companies",
      });
    },
  });

  const [url, setUrl] = useState("");
  const [companyId, setCompanyId] = useState<string | null>(null);
  const [step, setStep] = useState(1);

  useEffect(() => {
    if (showURLForm) {
      setStep(1);
    }
  }, [showURLForm]);

  return (
    <DialogContent className="sm:max-w-[450px] max-h-[95vh] overflow-y-auto">
      <div className="mr-6">
        <ProgressIndicator step={step} totalSteps={2} />
      </div>
      {step === 1 && (
        <EnterCompanyURLView
          setUrl={setUrl}
          submit={() => mutation.mutate({ url })}
          pending={mutation.isPending}
        />
      )}
      {step === 2 && (
        <AddCompanyURLsView companyId={companyId} didPressDone={() => setShowURLForm(false)} />
      )}
    </DialogContent>
  );
};
