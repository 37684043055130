import React, { useState } from "react";
import { ChevronRight } from "lucide-react";
import { cn } from "~/utils/helpers";
import { Company } from "~/models/Company";
import { CompanyLogo } from "~/components/ui/CompanyLogo";
import { Answer } from "~/models/Answer";
import { Sheet, SheetContent, SheetHeader } from "~/shadcn/components/ui/sheet";
import { PageEventTypePill } from "../ui/PageEventTypePill";
import { URLSummaryCell } from "../ui/URLSummaryCell";
import { Separator } from "~/shadcn/components/ui/separator";
import { useNavigate } from "react-router-dom";

export const AnswerCell = ({
  answer,
  index,
  company,
}: {
  answer: Answer;
  index: number;
  company: Company;
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const sources = answer.sources || [];
  const navigate = useNavigate();

  return (
    <Sheet open={isDialogOpen} onOpenChange={setIsDialogOpen}>
      <div
        key={index}
        onClick={() => {
          setIsDialogOpen(!isDialogOpen);
        }}
        className={cn(
          "flex flex-col gap-2 border p-4 rounded-lg shadow-sm cursor-pointer",
          index === 0 ? "ml-16" : "",
        )}
      >
        <div className="flex items-center w-full gap-2">
          <CompanyLogo url={company?.url} />
          <div className="text-sm font-medium w-full">Q: {answer.question}</div>
          <ChevronRight className="w-4 h-4 text-blue-600" />
        </div>
        <div className="w-[320px] text-left h-full mb-4 line-clamp-3">{answer.answer}</div>
      </div>
      <SheetContent>
        <SheetHeader>
          <div className="flex items-center gap-2 mb-8">
            <CompanyLogo url={company?.url} />
            <div className="text-sm font-medium">{company?.name}</div>
          </div>
        </SheetHeader>

        <div className="flex flex-col gap-8 overflow-y-auto max-h-[calc(100vh-5rem)]">
          <div className="flex flex-col gap-2">
            <div className="text-xs font-medium">Question</div>
            <div className="text-sm p-2 bg-slate-50 bg-border-100 border text-left rounded-md">
              {answer.question}
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <div className="text-xs font-medium">Answer</div>
            <div className="text-sm p-2 bg-slate-50 bg-border-100 border text-left rounded-md">
              {answer.answer}
            </div>
          </div>

          <div className="my-4">
            <Separator />
          </div>

          <div className="flex flex-col gap-4">
            <div className="text-xs font-medium text-slate-500">Sources</div>

            {sources.length === 0 && (
              <div className="text-xs text-slate-500 border border-slate-100 bg-slate-50 rounded-md p-2">No sources available</div>
            )}

            <div className="flex flex-col gap-2 mb-20">
              {sources.map((source, index) => (
                <>
                  <button
                    key={index}
                    className="mt-2 flex flex-col items-start"
                    onClick={() => {
                      if (source.tracked_page_id) {
                        navigate({
                          pathname: `/page-changes/${source.tracked_page_id}`,
                        });
                      } else {
                        window.open(source.url, "_blank");
                      }
                    }}
                  >
                    <PageEventTypePill type={source.kind} />
                    <URLSummaryCell url={source.url} description={source.summary} />
                  </button>

                  {index < sources.length - 1 && <Separator />}
                </>
              ))}
            </div>
          </div>
        </div>
      </SheetContent>
    </Sheet>
  );
};
