import { useQuery, useMutation } from "@tanstack/react-query";
import { queryClient } from "~/utils/client";
import { fetch_get, fetch_post } from "~/utils/helpers";

export interface UserSettings {
  detect_additions_deletions?: boolean;
}

export const useUserSettings = () => {
  return useQuery({
    queryKey: ["user_settings"],
    queryFn: () => fetch_get(`/api/user_settings`).then((response) => response.json()),
  });
};

export const useUpdateUserSettings = () => {
  return useMutation({
    mutationFn: (data: UserSettings) => fetch_post(`/api/user_settings`, { data }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["user_settings"] });
    },
  });
};
